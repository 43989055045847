import { Component, Inject } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialog } from '@angular/material/dialog';
import { Address, Entity } from '@upkeeplabs/models/cogent';
import { SearchResultModel} from '@cogent/shared/models/common/search-result.model';
import { DataApiService } from '@cogent/client/shared/services/api/data-api.service';
import { EntityApiService } from "@cogent/client/shared/services/api/entity-api.service";
import { UtilitiesService } from '@cogent/client/shared/logic/utilities';

@Component({
    templateUrl: './entity-find-modal.component.html',
    styleUrls: ['./entity-find-modal.component.css'],
})
export class EntityFindModalComponent {

    newEntity: Entity;
    canSave = false;
    canParentSave = false;
    searchFor: string;
    selectedIndex = 0;
    savingNew = false;
    searchResults: SearchResultModel[];
    parentSearchResults: SearchResultModel[];
    searching: boolean;
    parentSearchFor: string;
    parent: SearchResultModel;
    newParentEntity: Entity;

    public static FindEntity(dialog: MatDialog, type: string, callback: (entity: Entity) => any) {
        dialog.open(EntityFindModalComponent, { data: new EntityFindModel(type, callback) });
    }

    constructor(
        @Inject(MAT_DIALOG_DATA) public data: EntityFindModel,
        private dataAccessService: DataApiService,
        private entityApi: EntityApiService,
        private dialog: MatDialog
    ) {

        this.newEntity = new Entity();
        this.newEntity.address = new Address();
        this.newEntity.type = this.data.entityType;
        this.newEntity.id = UtilitiesService.newid();

    }

    get title() {
        if (this.data.entityType === 'Agent') {
            return 'Find the Agent';
        } else if (this.data.entityType === 'ClosingOfficer') {
            return 'Find the Closing Officer';
        } else if (this.data.entityType === 'Branch') {
            return 'Find Real Estate Branch';
        } else if (this.data.entityType === 'Real_Estate_Company') {
            return 'Find Real Estate Company';
        } else if (this.data.entityType === 'Title_Company') {
            return 'Find Title Company';
        } else if (this.data.entityType === 'Loan_Officer') {
            return 'Find Loan Officer';
        } else if (this.data.entityType === 'Insurance_Agent') {
            return 'Find Insurance Agent';
        } else {
            return 'Find Entity';
        }
    }

    get parentTitle() {
        if (this.data.entityType === 'Agent') {
            return 'Find the Agent\'s Branch';
        } else if (this.data.entityType === 'ClosingOfficer') {
            return 'Find the Closing Officer\'s Title Company ';
        } else if (this.data.entityType === 'Loan_Officer') {
            return 'Find the Loan Officer\'s Mortgage Company';
        } else if (this.data.entityType === 'Insurance_Agent') {
            return 'Find The Insurance Agent\'s Company';
        } else {
            return 'Find Entity';
        }
    }

    get newParentEntryTitle() {
        if (this.data.entityType === 'Agent') {
            return 'Enter the Agent\'s Branch';
        } else if (this.data.entityType === 'ClosingOfficer') {
            return 'Enter the Closing Officer\'s Title Company ';
        } else {
            return 'Find Entity';
        }
    }

    createNewEntity() {
        this.selectedIndex = 1;
        this.newEntity = new Entity();
        this.newEntity.address = new Address();
        this.newEntity.type = this.data.entityType;
        this.newEntity.id = UtilitiesService.newid();
        this.newEntity.name = this.searchFor;
    }

    doSearch() {
        this.searchResults = null;
        this.searching = true;
        this.dataAccessService.searchForTypeAndSubtype('Entity', this.data.entityType, this.searchFor).then(searchResults => {
            this.searching = false;
            this.searchResults = searchResults;
        });
    }

    doParentSearch() {
        let type = '';
        if (this.data.entityType === 'Agent') {
            type = 'Branch';
        } else if (this.data.entityType === 'ClosingOfficer') {
            type = 'Title_Company';
        } else if (this.data.entityType === 'Loan_Officer') {
            type = 'Mortgage_Company';
        } else if (this.data.entityType === 'Insurance_Agent') {
            type = 'Insurance_Branch';
        } else if (this.data.entityType === 'Insurance_Branch') {
            type = 'Insurance_Company';
        }

        this.parentSearchResults = null;
        this.searching = true;

        this.dataAccessService.searchForTypeAndSubtype('Entity', type, this.parentSearchFor).then(searchResults => {
            this.searching = false;
            this.parentSearchResults = searchResults;
        });
    }

    createNewParent() {
        this.newParentEntity = new Entity();
        this.newParentEntity.address = new Address();
        if (this.data.entityType === 'Agent') {
            this.newParentEntity.type = 'Branch';
        } else if (this.data.entityType === 'ClosingOfficer') {
            this.newParentEntity.type = 'Title_Company';
        } else if (this.data.entityType === 'Loan_Officer') {
            this.newParentEntity.type = 'Mortgage_Company';
        } else if (this.data.entityType === 'Insurance_Agent') {
            this.newParentEntity.type = 'Insurance_Branch';
        } else if (this.data.entityType === 'Insurance_Branch') {
            this.newParentEntity.type = 'Insurance_Company';
        }

        this.newParentEntity.id = UtilitiesService.newid();
        this.selectedIndex = 2;
        this.newParentEntity.name = this.parentSearchFor;
    }

    selectResult(result: SearchResultModel) {
        (<any>result).selecting = true;
        this.entityApi.getFullEntity(result.id).then(entity => {
            this.data.callback(entity);
            this.dialog.closeAll();
        });
    }

    selectParentResult(result: SearchResultModel) {
        this.parent = result;
        this.newEntity.parentId = result.id;
        this.selectedIndex = 3;
    }

    saveNewEntity() {
        this.savingNew = true;

        this.entityApi.updateEntity(this.newEntity, false).then(entity => {
            this.data.callback(this.newEntity);
            this.dialog.closeAll();
        });
    }

    saveNewParentEntity() {
        this.savingNew = true;
        this.entityApi.updateEntity(this.newParentEntity, false).then(entity => {
            this.newEntity.parent = entity;
            this.newEntity.parentId = entity.id;
            this.selectedIndex = 3;
            this.savingNew = false;
        });
    }
}

export class EntityFindModel {
    constructor(public entityType: string, public callback: (entity: Entity) => any) { }
}
