import { Routes, RouterModule } from '@angular/router';
import { AuthGuardService } from '@upkeeplabs/authentication';
import { ViewQuoteComponent } from './pages/view-quote/view-quote.component';
import { QuotePathSelectionComponent } from './pages/quote-path-selection/quote-path-selection.component';
import { NewQuoteComponent } from './pages/new-quote/new-quote.component';
import { OrdersComponent } from './pages/orders/orders.component';
import { NgModule } from '@angular/core';
import { HomeComponent } from './pages/home/home.component';
import { SettingsComponent } from './pages/settings/settings.component';
import { RegisterComponent } from './pages/register/register.component';
import { ResetPasswordComponent } from './pages/reset-password/reset-password.component';
import { ViewPolicyComponent } from './pages/view-policy/view-policy.component';
import { NewOrderFrameComponent } from './pages/new-order-frame/new-order-frame.component';
import { RequestServiceComponent } from './pages/request-service/request-service.component';
import { ChangeCoverageComponent } from './pages/change-coverage/change-coverage.component';
import { MarketingComponent } from '@cogent/client/apps/real-estate/marketing/marketing.component';

const appRoutes: Routes = [
    {
        path: 'auth',
        loadChildren: () => import('@upkeeplabs/authentication').then(mod => mod.AuthenticationModule)
    },
    {
        path: 'home',
        component: HomeComponent,
        canActivate: [AuthGuardService]
    },
    {
        path: 'new-order',
        component: NewOrderFrameComponent,
        canActivate: [AuthGuardService],
    },
    {
        path: 'request-service/:id',
        component: RequestServiceComponent,
        canActivate: [AuthGuardService],
    },
    {
        path: 'view-quote/:id',
        component: ViewQuoteComponent,
        canActivate: [AuthGuardService],
    },
    {
        path: 'view-subscription/:id',
        component: ViewPolicyComponent,
        canActivate: [AuthGuardService]
    },
    {
        path: 'quote-type',
        component: QuotePathSelectionComponent,
        canActivate: [AuthGuardService],
    },
    {
        path: 'change-coverage/:id',
        component: ChangeCoverageComponent,
        canActivate: [AuthGuardService]
    },
    {
        path: 'new-quote',
        component: NewQuoteComponent,
        canActivate: [AuthGuardService],
    },
    {
        path: 'quote-by-amount-to-spend/:amount',
        component: NewQuoteComponent,
        canActivate: [AuthGuardService],
    },
    {
        path: 'orders',
        component: OrdersComponent,
        canActivate: [AuthGuardService],
    },
    {
        path: 'settings',
        component: SettingsComponent,
        canActivate: [AuthGuardService],
    },
    {
        path: 'order/:id',
        component: NewQuoteComponent,
        canActivate: [AuthGuardService]
    },
    {
        path: 'security/reset-password',
        component: ResetPasswordComponent,
    },
    {
        path: 'security/register',
        component: RegisterComponent,
    },
    {
        path: 'marketing',
        component: MarketingComponent,
    },
    { path: '', redirectTo: '/home', pathMatch: 'full' }
];

@NgModule({
    imports: [RouterModule.forRoot(appRoutes, {})],
    exports: [RouterModule]
  })
  export class AppRoutingModule { }