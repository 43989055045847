import { CommonModule } from '@angular/common';
import { Component, OnInit } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { MatButtonModule } from '@angular/material/button';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatIconModule } from '@angular/material/icon';
import { MatRadioModule } from '@angular/material/radio';
import { MatSlideToggleModule } from '@angular/material/slide-toggle';
import { DomSanitizer, SafeUrl } from '@angular/platform-browser';
import { ActivatedRoute } from '@angular/router';
import { ApiService } from '@cogent/client/api';
import { EntityApiService } from '@cogent/client/shared/services/api/entity-api.service';
import { Entity, Tag } from '@upkeeplabs/models/cogent';

@Component({
    selector: 'app-marketing',
    standalone: true,
    imports: [CommonModule, MatButtonModule, MatRadioModule, MatIconModule, MatSlideToggleModule, FormsModule, MatFormFieldModule],
    templateUrl: './marketing.component.html',
    styleUrl: './marketing.component.scss'
})
export class MarketingComponent implements OnInit {

    sendPostcards = false;
    currentEntity: Entity;
    customerId: string;
    imageUrl: string;
    redboxTag: Tag;
    flipped = false;
    anniversary = false;
    parentImageUrl: string;
    checklistUrl: SafeUrl;

    constructor(private entityApi: EntityApiService,
        private domSanitizer: DomSanitizer,
        private activatedRoute: ActivatedRoute
    ) {

    }

    ngOnInit(): void {
        this.entityApi.getLoggedInUser(true).then(user => {
            this.currentEntity = user;
            this.customerId = user.id;

            this.imageUrl = `${this.entityApi.getImageUrl(this.currentEntity.id)}?v=${new Date().getTime()}`;
            this.parentImageUrl = `${this.entityApi.getImageUrl(this.currentEntity.parentId)}?v=${new Date().getTime()}`;
            this.entityApi.getTags(this.currentEntity.id).then(tags => {
                this.redboxTag = tags.find(i => i.id.toUpperCase() === '1990A535-C2FD-4847-B4A2-DB9858D3FDD9');
                if (this.redboxTag) {
                    this.sendPostcards = true;
                } else {
                    this.sendPostcards = false;
                }
            });

            this.checklistUrl = this.domSanitizer.bypassSecurityTrustResourceUrl(`${ApiService.endPointNode}real-estate/move-in-check-list/${user.id}`);
        });
    }


    showFileUpload(id) {
        document.getElementById(id).click();
    }

    handleFiles(files, target) {
        if (files.srcElement) {
            files = files.srcElement.files;
        }
        for (let i = 0; i < files.length; i++) {
            const file = files[i];
            //this.imageType = file.type;
            const imageType = /^image\//;

            if (!imageType.test(file.type)) {
                continue;
            }
            const reader = new FileReader();

            const setUrl = (url) => {

                if (target === 'company-logo') {
                    this.parentImageUrl = url;
                } else {
                    this.imageUrl = url;
                }

            };
            reader.onload = (function () {
                return function (e) {

                    (this as any).dragTargetActive = false;
                    setUrl(e.target.result);

                };
            })();

            reader.readAsDataURL(file);
        }
    }
}
