import { Component, OnInit, HostListener, NgZone } from '@angular/core';
import { SalesApiService } from '@cogent/client/shared/services/api/sales-api.service';
import { SettingsApiService } from '@cogent/client/shared/services/api/settings-api.service';
import { PolicySummary } from '@cogent/client/shared/models/policies/policy-summary.model';
import { DialogsService } from '@cogent/client/shared/services/dialog-service/dialog.service';
import { Router } from '@angular/router';
import { ScrollAnimationService } from '@cogent/client/shared/services/common/scroll-animation.service';
import { ApiService } from '@cogent/client/api';
import { Entity, Quote } from '@upkeeplabs/models/cogent';
import { EntityApiService } from "@cogent/client/shared/services/api/entity-api.service";
import { RealEstatePortalResourceClient } from '@cogent/shared/models/sales/real-estate-portal-resource-client.model';
import { UtilitiesService } from '@cogent/client/shared/logic/utilities';
declare var Chart: any;

@Component({
    selector: 'app-home',
    templateUrl: './home.component.html',
    styleUrls: ['./home.component.css']
})
export class HomeComponent implements OnInit {

    policies: PolicySummary[];
    filter: string;
    ratingPercent = '0%';
    surveyCount = 0;
    noRatings = false;
    moveInChecklistUrl: string;

    constructor(private salesApi: SalesApiService,
        private entityApi: EntityApiService,
        private dialogService: DialogsService,
        private router: Router,
        private ngZone: NgZone,
        private settingsApi: SettingsApiService,
        private scrollAnimation: ScrollAnimationService,
        private api: ApiService) { }


    private get totalSavingsCalculated() {
        if (!this.expensesByTrade) {
            return 0;
        }

        return this.expensesByTrade.map(i => i.totalExpenses).reduce((a, b) => a + b);
    }

    get quoteCount() {
        if (!this.quotes) {
            return 0;
        }

        return this.quotes.length;
    }

    get policyCount() {
        if (!this.policies) {
            return 0;
        }

        return this.policies.length;
    }

    get aePhotoURL() {
        if (!this.accountExecutive) {
            return null;
        }

        return `${ApiService.endPointDotNet}entity/thumbnail/${this.accountExecutive.id}`;
    }
    get totalSavingsDisplay(): number {
        return this.totalSavings;
    }

    expensesByTrade: any[];
    accountExecutive: Entity;
    loggedInUser: Entity;
    scrollPosition = 0;
    baseUrl = ApiService.endPointDotNet;
    quotes: Quote[];
    mappedExpenses: any[];
    userUrl: string;
    resources: RealEstatePortalResourceClient[];
    noExpenses = false;

    savingsInterval: any;

    private totalSavings = 0;
    ngOnInit() {

        // setTimeout(() => {
        //     // TODO: make this dynamic
        //     this.ratingPercent = '85%';
        // }, 2000);

        this.salesApi.getReferrerExpensesByTrade().then(expenses => {
            const colors = [
                '#9600FF', '#AEBAF8', 'blue', 'orange', 'purple', 'yellow'
            ];

            let metrics = expenses.map(i => ({ name: i.tradeName, value: i.totalExpenses * 1.25, color: colors[expenses.indexOf(i)] }));
            if (metrics.length === -0) {
                metrics = [{ name: 'HVAC', value: 1500, color: colors[0] }, { name: 'Plumbing', value: 600, color: colors[1] }, { name: 'Appliance', value: 800, color: colors[2] }];
                this.noExpenses = true;
            }
            this.mappedExpenses = metrics;
        });

        // this.settingsApi.getRealEstatePortalResources().then(resources => this.resources = resources);

        // this.salesApi.getQuotes().then(quotes => this.quotes = quotes);
        this.salesApi.getMyAE().then(async ae => {
            if (ae && ae.assignedAccountExecutiveId) {
                this.entityApi.getFullEntity(ae.assignedAccountExecutiveId).then(accountExecutive => {
                    this.accountExecutive = accountExecutive;
                    this.accountExecutive.mobileNumber.number = UtilitiesService.formatPhoneNumber(this.accountExecutive.mobileNumber.number);
                });

                const unassignedResources = await this.settingsApi.getRealEstatePortalResourcesUnassigned();
                const assignedResources = await this.settingsApi.getRealEstatePortalResourcesForSalesRep(ae.assignedAccountExecutiveId);
                const combined = unassignedResources.concat(assignedResources).sort((a, b) => a.sort > b.sort ? 1 : -1);
                this.resources = combined;
            } else {
                this.settingsApi.getRealEstatePortalResources().then(resources => this.resources = resources);
            }
        });

        this.entityApi.getLoggedInUser(false).then(loggedInUser => {
            this.loggedInUser = loggedInUser;
            this.userUrl = `${this.entityApi.getImageUrl(this.loggedInUser.id)}`;
            this.moveInChecklistUrl = `${ApiService.endPointNode}real-estate/move-in-check-list/${this.loggedInUser.id}`;
            if (!loggedInUser.parentId) {
                setTimeout(() => {
                    const ref = this.dialogService.confirm('No Organization Set', 'It looks like we don\'t have your organization setup yet.  Would you like to do that now?');
                    ref.subscribe(results => {
                        if (results) {
                            this.router.navigate(['/settings'], { queryParams: { changeCompany: true } });
                        }
                    });
                }, 3000);

            }
        });

        this.entityApi.getAgentPortalPolicies().then(policies => {
            this.policies = policies;
        });

        this.entityApi.getAgentAverageWorkOrderRatings().then(ratings => {
            if (ratings) {
                let ratingPct = 0;
                if (ratings.available !== 0) {
                    ratingPct = (ratings.stars / ratings.available) * 100;
                }
                this.ratingPercent = ratingPct.toString() + '%';
                this.surveyCount = ratings.surveyCount;
                if (ratings.surveyCount === 0) {
                    this.noRatings = true;
                }
            }
        });
    }

    getResourceThumbnail(resource: RealEstatePortalResourceClient) {
        return `${ApiService.endPointDotNet}RealEstatePortalResource/${resource.id}/thumbnail`;
    }

    getResourceUrl(resource: RealEstatePortalResourceClient) {
        return `${ApiService.endPointDotNet}RealEstatePortalResource/${resource.id}/file`;

    }

    scrollToFragment(fragment: string) {
        this.scrollAnimation.scrollToElement(fragment);
    }

    get filteredPolicies() {
        if (!this.filter) {
            return this.policies;
        }

        const filterLower = this.filter.toLocaleLowerCase();
        return this.policies.filter(i =>
            (i.propertyAddress && i.propertyAddress.address1 && i.propertyAddress.address1.toLowerCase().indexOf(filterLower) > -1)
            ||
            (i.policyNumber.toString() === filterLower)
            ||
            (i.planName.toLowerCase().indexOf(filterLower) > -1)
            ||
            (i.status.toLowerCase().indexOf(filterLower) > -1)
        );
    }

}
