<div id="first-section" class="">
    <div class="container">
        <mat-tab-group class="hide-tabs" [(selectedIndex)]="selectedIndex"
            (selectedIndexChange)="selectedIndexChange($event)">
            <mat-tab label="First">

                <div class="container padding">
                    <div aflex>
                        <div>
                            <h1 class="page-title white">
                                <span class="large-print">Your</span><br>
                                <span class="little">Account</span>
                            </h1>
                        </div>
                        <div nogrow>

                            <button mat-button mat-raised-button color="primary" (click)="step1GoNext()"
                                [disabled]="!step1CanGoNext || saving">
                                <div>
                                    <mat-spinner *ngIf="saving" class="thirty"></mat-spinner>
                                    Next <mat-icon>chevron_right</mat-icon>
                                </div>
                            </button>

                        </div>
                    </div>
                </div>

                <!-- <mat-card topmargin30> -->









                <div class="small-container1 fuzzy-container fuzzy" style="position: relative;" topmargin40>

                    <div class="icon-container">
                        <mat-icon>account_circle</mat-icon>
                    </div>

                    <div>

                        <h2>Your Role?</h2>
                        <button mat-button class="role-button" (click)="login.entityType = 'Agent'"
                            [class.selected]="login.entityType === 'Agent'">
                            <img src="../../../assets/images/agent.png" class="type-icon">
                            <br>
                            Real Estate<br>Agent
                        </button>

                        <button mat-button class="role-button" (click)="login.entityType = 'ClosingOfficer'"
                            [class.selected]="login.entityType === 'ClosingOfficer'">
                            <img src="../../../assets/images/closing-officer.svg" class="type-icon">
                            <br>
                            Closing<br>Officer
                        </button>

                        <button mat-button class="role-button" (click)="login.entityType = 'TransactionCoordinator'"
                            [class.selected]="login.entityType === 'TransactionCoordinator'">
                            <img src="../../../assets/images/transaction-coordinator.svg" class="type-icon">
                            <br>
                            Transaction<br>Coordinator
                        </button>

                        <button mat-button class="role-button" (click)="login.entityType = 'Loan_Officer'"
                            [class.selected]="login.entityType === 'Loan_Officer'">
                            <img src="../../../assets/images/loan-officer.svg" class="type-icon">
                            <br>
                            Loan<br>Officer
                        </button>

                        <button mat-button class="role-button" (click)="login.entityType = 'Insurance_Agent'"
                            [class.selected]="login.entityType === 'Insurance_Agent'">
                            <img src="../../../assets/images/insurance-agent.svg" class="type-icon">
                            <br>
                            Insurance<br>Agent
                        </button>




                        <mat-radio-group [(ngModel)]="login.entityType">
                            <!-- <mat-radio-button class="inline" value="Agent">
                            <img src="../../../assets/images/agent.svg" class="type-icon">
                            Real Estate Agent</mat-radio-button> -->
                            <!-- <mat-radio-button class="inline" value="ClosingOfficer">
                            <img src="../../../assets/images/closing-officer.svg" class="type-icon">
                            Closing Officer</mat-radio-button> -->
                            <!-- <mat-radio-button class="inline" value="TransactionCoordinator">
                            <img src="../../../assets/images/loan-officer.svg" class="type-icon">
                            Transaction Coordinator</mat-radio-button> -->
                        </mat-radio-group>
                        <mat-form-field appearance="outline">
                            <mat-label>Email *</mat-label>
                            <input matInput type="email" autocomplete="new-password" [class.warning-input]="loginExists"
                                [(ngModel)]="entity.email">
                        </mat-form-field>
                        <div id="login-exists-container" class="warning-message fadeInUp" *ngIf="loginExists">
                            <span>Sorry, the email you have selected is already tied to an existing account</span>
                        </div>
                        <mat-form-field appearance="outline">
                            <mat-label>Password *</mat-label>
                            <input matInput type="password" autocomplete="new-password"
                                [class.warning-input]="passwordsDoNotMatch" [(ngModel)]="login.passwordHash">
                        </mat-form-field>
                        <mat-form-field appearance="outline">
                            <mat-label>Confirm Password *</mat-label>
                            <input matInput type="password" autocomplete="new-password"
                                [class.warning-input]="passwordsDoNotMatch" [(ngModel)]="confirmPassword">
                        </mat-form-field>

                        <div id="passwords-do-not-match-container" class="warning-message fadeInUp"
                            *ngIf="passwordsDoNotMatch">
                            <span>
                                <mat-icon>warning</mat-icon> The passwords do not match
                            </span>
                        </div>
                    </div>
                </div>
                <!-- </mat-card> -->


            </mat-tab>
            <mat-tab>
                <div aflex class="padding">
                    <div>
                        <h1 class="page-title white">
                            <span class="large-print">Existing</span><br>
                            <span class="little">Users</span>
                        </h1>
                    </div>
                    <div nogrow>

                    </div>
                </div>

                <div class="small-container1 fuzzy-container fuzzy">

                    <p class="info1">We found some existing users in our system.
                        You can link your account to one of these existing records, or you can create a new user record.
                    </p>



                    <div topmargin40></div>
                    <div *ngFor="let entity of existingEntities" class="home-list-item" aflex>
                        <div nogrow>
                            <mat-icon>account_box</mat-icon>

                        </div>
                        <div>
                            <div style="padding-top: 8px;"> <span class="property-address"> {{entity.name}}
                                    ({{entity.parentName}})</span>

                                <div *ngIf="!entity.showCodeEntry">
                                    <mat-radio-group class="no-bold" aria-label="Select an option"
                                        [(ngModel)]="entity.sendOption">
                                        <mat-radio-button *ngIf="entity.homeNumberId" value="Home">Send SMS Message to
                                            {{entity.homeNumber}}</mat-radio-button>
                                        <mat-radio-button *ngIf="entity.mobileNumberId" value="Mobile">
                                            Send SMS Message to {{entity.mobileNumber}}
                                        </mat-radio-button>
                                        <mat-radio-button *ngIf="entity.email" value="Email">
                                            Send Email Message to {{entity.email}}
                                        </mat-radio-button>
                                    </mat-radio-group>

                                    <div topmargin20></div>
                                    <button mat-button mat-raised-button color="primary"
                                        (click)="showVerifyDialog(entity)"
                                        [disabled]="!entity.sendOption || sendingRegistrationCode">
                                        <mat-spinner *ngIf="sendingRegistrationCode" class="thirty"></mat-spinner>
                                        Send Verification Code
                                    </button>
                                </div>
                            </div>


                            <div *ngIf="entity.showCodeEntry" topmargin20>
                                <mat-form-field style="max-width: 200px;" appearance="outline">
                                    <mat-label>Code</mat-label>
                                    <input matInput type="number" [(ngModel)]="registrationCode">
                                </mat-form-field>

                                <button mat-button mat-raised-button color="primary" (click)="registerWithCode(entity)"
                                    [disabled]="!registrationCode || saving">
                                    <mat-spinner *ngIf="saving" class="thirty"></mat-spinner>
                                    Register
                                </button>
                                <button mat-button (click)="entity.showCodeEntry = false">Send Another Code</button>
                            </div>
                        </div>



                    </div>

                    <button mat-button block (click)="selectedIndex = 2">Skip <mat-icon>chevron_right</mat-icon>
                    </button>
                </div>

            </mat-tab>
            <mat-tab label="Second">

                <div aflex>
                    <div>
                        <h1 class="page-title white">
                            <span class="large-print">Contact</span><br>
                            <span class="little">Information</span>
                        </h1>
                    </div>
                    <div nogrow>

                        <button mat-button mat-raised-button color="primary" (click)="save()"
                            [disabled]="!canSave || saving" large>
                            <mat-spinner *ngIf="saving" class="thirty"></mat-spinner>
                            Register
                        </button>

                    </div>
                </div>

                <div class="small-container1 fuzzy-container fuzzy" topmargin40>
                    <app-basic-entity-entry [hideTimezoneEntry]="true" [nameIsSearchBox]="false"
                        [showRequiredOfficeNumber]="false" [(canSave)]="canSaveEntity" [hidePreferreds]="true"
                        [hideInactiveBox]="true" [showRequiredMobileNumber]="false" [showRequiredEmail]="true"
                        [showRequiredName]="true" [showPhoneNumberEntry]="true" [entity]="entity">
                    </app-basic-entity-entry>
                </div>
            </mat-tab>
            <mat-tab label="Third">


                <!-- <img class="success-icon" src="../../../../assets/images/success-icon.png"> -->

                <div class="small-container1 fuzzy-container fuzzy">
                    <div style="text-align: center;">

                        <div topmargin20></div>
                        <app-check-circle-animated></app-check-circle-animated>

                        <h1 topmargin40>Registration Successful</h1>
                        <p style="font-style: italic;">You can now log in</p>


                        <a topmargin30 mat-button mat-raised-button color="primary" large routerLink="/">Log In</a>
                    </div>
                </div>

            </mat-tab>
        </mat-tab-group>
    </div>
</div>