<div style="padding: 20px">

    <mat-tab-group class="hide-tabs" [(selectedIndex)]="selectedIndex">
        <mat-tab label="First">
            <div aflex>
                <div>
                    <h2 class="dialog-title">Take Payment</h2>
                </div>
                <div nogrow>
                    <button mat-button mat-dialog-close>
                        <mat-icon>close</mat-icon>
                    </button>
                </div>
            </div>
            <div *ngIf="customerId" topmargin30>
                <!-- 
                    <div flex>
                        <div>
                            <div class="data-label">Total Due</div>
                            <h1 class="big-data">{{amountDue | currency}}</h1>
                        </div>
                    </div> 
                -->

                <div>
                    <mat-radio-group aria-label="Select an option" [(ngModel)]="fullOrPart"
                        (ngModelChange)="paymentChanged($event)">
                        <mat-radio-button value="full">Pay Invoice In Full ({{amountDue | currency}})</mat-radio-button>
                        <mat-radio-button value="partial">Make A Partial Payment</mat-radio-button>
                    </mat-radio-group>
                </div>

                <div *ngIf="fullOrPart === 'partial'">
                    <mat-form-field appearance="outline" style="max-width: 150px;">
                        <mat-label>Amount</mat-label>
                        <input matInput type="number" [(ngModel)]="paymentAmount">
                    </mat-form-field>
                </div>



                <app-payment-method-entry [customerId]="customerId" [newCardButtonText]="newCardButtonText"
                    [(selectedCard)]="selectedCard"></app-payment-method-entry>

                <div flex topmargin20>
                    <div>
                        <div class="data-label">Payment Amount</div>
                        <h1 class="big-data" style="min-height: 74px; min-width: 200px;">{{paymentAmount | currency}}</h1>
                    </div>
                    <div nogrow style="padding-top: 49px;">
                        <button mobile-block mat-button mat-raised-button color="primary" (click)="save()"
                            [disabled]="!canSave">
                            <mat-spinner *ngIf="saving" class="thirty"></mat-spinner> Make Payment
                        </button>
                    </div>
                </div>
            </div>
        </mat-tab>
        <mat-tab label="Second">

            <div class="center padding" *ngIf="selectedIndex === 1">

                <div topmargin40></div>
                <app-check-circle-animated></app-check-circle-animated>

                <h1 topmargin40>Payment Successful</h1>


                <div topmargin40 *ngIf="payment">
                    <a mat-button mat-raised-button mat-raised-button color="primary"
                        href="{{endpoint}}payment/{{paymentId}}/pdf" target="_blank">Download Receipt</a><br><br>
                    <button mat-button mat-dialog-close>Close</button>
                </div>
            </div>
        </mat-tab>
    </mat-tab-group>
</div>