<div id="first-section" class="">
    <div class="container">

        <div class="overview-box fuzzy">


            <div style="width: 100%; max-width: 8.8in;display: table;margin: 0 auto">

                <iframe *ngIf="checklistUrl"
                    [src]="checklistUrl"
                    style="width: 8.8in;
            border: none;
            height: 11.1in;"></iframe>
            </div>

        </div>
    </div>
</div>