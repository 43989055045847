import { Component, Inject } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialog } from '@angular/material/dialog';
import { ServiceRepositoryService } from '@cogent/client/shared/services/api/service-repository';



@Component({
    templateUrl: './view-coverage.component.html',
    styleUrls: ['./view-coverage.component.css'],
})
export class ViewCoverageDialogComponent {
    planDetail: any;
    loading = false;

    constructor(
        @Inject(MAT_DIALOG_DATA) public data: any,
        public dialog: MatDialog,
        private planRepository: ServiceRepositoryService
    ) {
        const planItemId: string = data;


        this.loading = true;
        this.planRepository.getCoverageLanguage(planItemId).then(detail => {
            this.planDetail = detail;
            this.loading = false;
        });
    }
}
