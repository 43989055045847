<div aflex id="dialog-container">
    <div>
        <h1 mat-dialog-title cdkDrag cdkDragRootElement=".cdk-overlay-pane" cdkDragHandle >Send Quote</h1>
    </div>
    <div nogrow>
        <button mat-button [mat-dialog-close] cdkFocusInitial>
            <mat-icon>close</mat-icon>
        </button>
    </div>
</div>
<div mat-dialog-content>
    <mat-form-field>
        <input matInput style="width: 400px" [(ngModel)]="email" placeholder="Email" type="email">
    </mat-form-field>
</div>

<div mat-dialog-actions>
    <button mat-button mat-raised-button color="primary" (click)="send()" [disabled]="!canSend || sending">
        <mat-spinner *ngIf="sending" class="thirty"></mat-spinner>
        Send</button>

</div>