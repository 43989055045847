import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';

@Component({
    selector: 'app-quote-path-selection',
    templateUrl: './quote-path-selection.component.html',
    styleUrls: ['./quote-path-selection.component.css']
})
export class QuotePathSelectionComponent implements OnInit {

    amountToSpend: number;
    constructor(private router: Router) { }

    ngOnInit() {
    }

    chooseMyPlan() {
        this.router.navigateByUrl(`quote-by-amount-to-spend/${this.amountToSpend}`);
    }

}
