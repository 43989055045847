<div class="container" topmargin30>

    <h1 class="page-title">
        <span class="large-print">Quote</span><br>
        <span class="little">Type</span>
    </h1>


    <div style="margin-top: 60px;" class="gray">

        <div block class="option-button">

            <div aflex>
                <div nogrow>

                    <img src="../../assets/images/select.svg" style="height: 100px">
                </div>
                <div>
                    <div bottommargin20>
                        Let me choose the plan and options
                    </div>

                    <a routerLink="/new-quote" mat-button mat-raised-button color="primary">Go
                        <mat-icon>chevron_right</mat-icon></a>
                </div>
            </div>
        </div>

        <div block class="option-button">
            <div aflex>
                <div nogrow>
                    <img src="../../assets/images/money-pick.svg" style="height: 100px">
                </div>
                <div>
                    <div bottommargin20>
                        I have
                        <input type="number" id="to-spend" placeholder="$0.00" [(ngModel)]="amountToSpend"> To Spend
                    </div>

                    <button mat-button mat-raised-button color="primary" [disabled]="!amountToSpend"
                        (click)="chooseMyPlan()">Go
                        <mat-icon>chevron_right</mat-icon>
                    </button>
                </div>
            </div>
        </div>
    </div>
</div>