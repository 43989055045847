<!-- <div id="background-image-container" [class.opacity-50]="scrollPosition > 50"
    [class.opacity-25]="scrollPosition > 100" [class.opacity-10]="scrollPosition > 150"
    [class.opacity-0]="scrollPosition > 200"></div> -->

<div style="z-index: 2; overflow-x: hidden">
    <div class="header" [class.is-scrolled]="isScrolled">
        <div aflex>

            <div nogrow >
                <button (click)="menuOpen = !menuOpen" mat-button id="hamburger">
                    <mat-icon>menu</mat-icon>
                </button>
                <a routerLink="/home">
                    <img id="logo" src="https://elevateh.blob.core.windows.net/cdn/images/customer-portal/elevate-logo-homescriptions.svg">
                </a>
            </div>
            <div id="top-menus">
                <a routerLink="/home" [class.selected]="isHome" class="top-link">Home</a>
                <a routerLink="/new-order" [class.selected]="isOrder" class="top-link">Order</a>
                <a routerLink="/settings" [class.selected]="isSettings" class="top-link">Settings</a>
                <a routerLink="/auth/log-out" class="top-link">Log Out</a>
            </div>
        </div>


        
    </div>
    <div id="app-content" [class.entering]="enteringAnimation" style="">
        <router-outlet></router-outlet>
    </div>


    <div id="back-drop" *ngIf="menuOpen" (click)="menuOpen = false"></div>
    <div id="menu" *ngIf="menuOpen">

        <h1>
            <mat-icon>menu</mat-icon> Options
        </h1>
        <div style="display: flex; flex-direction: column; height: calc(100% - 20px)">

            <a *ngFor="let menuItem of menuItems" mat-button block (click)="doMenuItem(menuItem, $event)">
                {{menuItem.text}}
            </a>

            <img src="assets/images/elevate-a-icon-white.svg" class="bg-elevate-a">
        </div>
    </div>
</div>