export class OrdersByMonth {
    id: string;
    marketAreaId: string;
    marketAreaName: string;
    marketStateId: string;
    marketStateName: string;
    salesRegionId: string;
    salesRegionName: string;
    reportMonth: Date;
    policyCount: number;
    salesTarget: number;
    cancelledCount: number;
}