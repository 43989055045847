<div id="first-section" class="">
    <div class="container">

        <div class="overview-box fuzzy">
            <div aflex>
                <div>


                </div>
                <div nogrow>
                    <button style="margin-top: 10px; margin-right: 10px" mat-button mat-raised-button color="primary"
                        [disabled]="saving" (click)="save()" large>
                        <mat-spinner *ngIf="saving" class="thirty"></mat-spinner>
                        Save
                    </button>
                </div>
            </div>

            <mat-tab-group>
                <mat-tab label="Basic Information">
                    <div flex style="padding: 30px">
                        <div topmargin30>
                            <app-basic-entity-entry [hideTimezoneEntry]="true" [showFirstAndLastName]="false"
                                [nameIsSearchBox]="false" [hidePaperless]="true" [hidePreferreds]="true"
                                [hideInactiveBox]="true" [entity]="currentEntity" showPhoneNumberEntry="true">
                            </app-basic-entity-entry>
                        </div>
                        <div nogrow style="width: 250px">
                            <div class="data-label">Options</div>

                            <button mat-button block (click)="showChangePassword()">
                                <mat-icon>lock</mat-icon>
                                Change Password
                            </button>

                            <div class="data-label" topmargin30>
                                My Organization
                            </div>

                            <div *ngIf="parent">
                                <h2>{{parent.name}}</h2>

                                <address *ngIf="parent.address && parent.address.address1">
                                    {{parent.address.address1}}<br>
                                    {{parent.address.city}}, {{parent.address.state}} {{parent.address.postalCode}}
                                </address>


                                <button mat-button block topmargin30 (click)="changeParent()">
                                    <mat-icon>business</mat-icon>
                                    Change My Organization
                                </button>
                            </div>

                            <div *ngIf="!parent" style="text-align: center;" topmargin20>
                                <button block mat-button (click)="changeParent()">
                                    <img src="../../../assets/images/not-found.png" style="height: 75px;">

                                    <h2>Not Set</h2>
                                    <div style="text-transform: none;">Find My Organization</div>
                                </button>
                            </div>

                            <div class="data-label" topmargin30>Your Profile</div>
                            <app-swapable-image *ngIf="imageUrl" #swapableImage [(url)]="imageUrl"></app-swapable-image>

                        </div>
                    </div>
                </mat-tab>
                <mat-tab label="Marketing">
                    <div style="padding: 30px">
                        <mat-slide-toggle [(ngModel)]="sendPostcards">Send postcards to my clients from
                            me</mat-slide-toggle>

                        <div aflex id="postcard-outer-container">
                            <div>
                                <div id="postcard-container">


                                    <ng-container *ngIf="!flipped">
                                        <div id="upload-labels">
                                            Upload your headshot and company logo
                                        </div>
                                        <div class="dotted" id="logo-left"></div>
                                        <div class="dotted" id="logo-down"></div>
                                        <div class="dotted" id="headshot-right"></div>
                                        <div class="dotted" id="headshot-down"></div>
                                    </ng-container>

                                    <div style="height: 40px;"></div>

                                    <div *ngIf="!anniversary" class="flip-container slide-in-up"
                                        [class.flip-it]="flipped">
                                        <div class="flipper">
                                            <div class="front">
                                                <div class="postcard ">

                                                    <div aflex>
                                                        <div flex1
                                                            style="border-right: 1px dotted rgb(150,150,150);min-height: 290px;padding-right: 20px;">
                                                            <div>
                                                                <h1 class="fancy-title"
                                                                    style="color: #ef7b00 !important; text-align: left;">
                                                                    HELLO!</h1>

                                                                <p class="postcard-text">We know buying a new home can
                                                                    be
                                                                    stressful, so we
                                                                    wanted to treat you to a free Redbox movie
                                                                    rental. Take this card to any Redbox kiosk,
                                                                    enter the code below, and enjoy a move night on
                                                                    us!
                                                                </p>
                                                                <p>
                                                                    Code:<br>
                                                                    <span style="color: #ef7b00;">4512d2</span>
                                                                </p>
                                                            </div>

                                                            <div aflex style="margin-top: 107px;">
                                                                <div nogrow>
                                                                    <div
                                                                        style="width: 70px;height: 70px;border-radius: 100%;overflow: hidden;">
                                                                        <img style="height: 70px;cursor: pointer;"
                                                                            id="headshot-image"
                                                                            (click)="showFileUpload('headshot')"
                                                                            class="headshot-image" [src]="imageUrl">
                                                                    </div>
                                                                </div>
                                                                <div nogrow>
                                                                    <img id="company-logo-image"
                                                                        class="company-logo-image"
                                                                        [src]="parentImageUrl"
                                                                        style="height: 40px;cursor: pointer;"
                                                                        (click)="showFileUpload('company-logo')">
                                                                    <br>
                                                                </div>
                                                                <div>
                                                                    <div style="font-weight: bold;">Joe Agent</div>
                                                                    <div>Clement Realty</div>
                                                                    <div>000-000-0000</div>
                                                                    <div>joe&#64;agent.com</div>
                                                                </div>
                                                            </div>
                                                            <input type="file" id="company-logo" multiple
                                                                accept="image/*"
                                                                (change)="handleFiles($event, 'company-logo')">
                                                            <input type="file" id="headshot" multiple accept="image/*"
                                                                (change)="handleFiles($event, 'headshot')">
                                                        </div>
                                                        <div nogrow style="width:300px">
                                                            <div aflex>
                                                                <div>

                                                                </div>
                                                                <div nogrow>
                                                                    <img style="height: 70px;"
                                                                        src="../../assets/images/stamp.svg">
                                                                </div>
                                                            </div>


                                                            <div style="margin-left: 60px;margin-top: 100px;">
                                                                <img style="height: 21px;"
                                                                    src="../../assets/images/meter.png">
                                                                <h2 style="color: #333;text-transform: none;">Your
                                                                    Client
                                                                </h2>
                                                                <address style="line-height: 14px;">
                                                                    384 W Anystreet<br>
                                                                    Apt 102<br>
                                                                    My Town, UT 84111
                                                                </address>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                            <div class="back">
                                                <div class="postcard postcard-back">

                                                </div>
                                            </div>
                                        </div>
                                    </div>

                                    <div *ngIf="anniversary" class="flip-container slide-in-up"
                                        [class.flip-it]="flipped">
                                        <div class="flipper">
                                            <div class="front">
                                                <div class="postcard ">

                                                    <div aflex>
                                                        <div flex1
                                                            style="border-right: 1px dotted rgb(150,150,150);min-height: 290px;padding-right: 20px;">
                                                            <div>
                                                                <h1 class="fancy-title"
                                                                    style="color: #ef7b00 !important; text-align: left;">
                                                                    HAPPY ANNIVERSARY!</h1>

                                                                <p class="postcard-text">You've been in your home a year
                                                                    now
                                                                </p>
                                                                <p>
                                                                    Code:<br>
                                                                    <span style="color: #ef7b00;">4512d2</span>
                                                                </p>
                                                            </div>

                                                            <div aflex style="margin-top: 107px;">
                                                                <div nogrow>
                                                                    <div
                                                                        style="width: 70px;height: 70px;border-radius: 100%;overflow: hidden;">
                                                                        <img style="height: 70px;cursor: pointer;"
                                                                            id="headshot-image"
                                                                            (click)="showFileUpload('headshot')"
                                                                            class="headshot-image" [src]="imageUrl">
                                                                    </div>
                                                                </div>
                                                                <div nogrow>
                                                                    <img id="company-logo-image"
                                                                        class="company-logo-image"
                                                                        [src]="parentImageUrl"
                                                                        style="height: 40px;cursor: pointer;"
                                                                        (click)="showFileUpload('company-logo')">
                                                                    <br>
                                                                </div>
                                                                <div>
                                                                    <div style="font-weight: bold;">Joe Agent</div>
                                                                    <div>Clement Realty</div>
                                                                    <div>000-000-0000</div>
                                                                    <div>joe&#64;agent.com</div>
                                                                </div>
                                                            </div>
                                                            <input type="file" id="company-logo" multiple
                                                                accept="image/*"
                                                                (change)="handleFiles($event, 'company-logo')">
                                                            <input type="file" id="headshot" multiple accept="image/*"
                                                                (change)="handleFiles($event, 'headshot')">
                                                        </div>
                                                        <div nogrow style="width:300px">
                                                            <div aflex>
                                                                <div>

                                                                </div>
                                                                <div nogrow>
                                                                    <img style="height: 70px;"
                                                                        src="../../assets/images/stamp.svg">
                                                                </div>
                                                            </div>


                                                            <div style="margin-left: 60px;margin-top: 100px;">
                                                                <img style="height: 21px;"
                                                                    src="../../assets/images/meter.png">
                                                                <h2 style="color: #333;text-transform: none;">Your
                                                                    Client
                                                                </h2>
                                                                <address style="line-height: 14px;">
                                                                    384 W Anystreet<br>
                                                                    Apt 102<br>
                                                                    My Town, UT 84111
                                                                </address>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                            <div class="back">
                                                <div class="postcard postcard-back-2">

                                                </div>
                                            </div>
                                        </div>
                                    </div>

                                </div>
                            </div>
                            <div nogrow>
                                <div>
                                    <div class="data-label" bottommargin10>Card Type</div>
                                    <mat-radio-group aria-label="Select an option" [(ngModel)]="anniversary">
                                        <mat-radio-button [value]="false">Welcome</mat-radio-button><br>
                                        <mat-radio-button style="margin-top: 5px"
                                            [value]="true">Anniversary</mat-radio-button>
                                    </mat-radio-group>
                                </div>

                                <div class="data-label" topmargin40 bottommargin10>View</div>
                                <button mat-button class="toggle-button" [class.selected]="!flipped"
                                    (click)="flipped = false">
                                    <div>
                                        <mat-icon>topic</mat-icon><br>
                                        Front
                                    </div>
                                </button>
                                <br>
                                <button mat-button class="toggle-button" [class.selected]="flipped"
                                    (click)="flipped = true">
                                    <div>
                                        <mat-icon>panorama</mat-icon><br>
                                        Back
                                    </div>
                                </button>
                            </div>
                        </div>
                    </div>
                </mat-tab>
                <mat-tab label="Payment Methods">
    
                    <app-payment-method-entry [customerId]="customerId"></app-payment-method-entry>
                </mat-tab>
            </mat-tab-group>



        </div>
    </div>
</div>