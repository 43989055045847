import { Component, HostListener, OnInit } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { ActivatedRoute } from '@angular/router';
import { PolicyApiService } from '@cogent/client/shared/services/api/policy-api.service';
import { PlanApiService } from '@cogent/client/shared/services/api/plan-api.service';
import { PolicySummary } from '@cogent/client/shared/models/policies/policy-summary.model';
import { MissionService } from '@cogent/client/shared/services/mission-service';
import { UpdatePolicyCoverageArgs } from '@cogent/shared/models/policies/update-policy-coverage-args.model';
import { SalesItemModel } from '@cogent/shared/models/plans/sales-item.model';
import { UtilitiesService } from '@cogent/client/shared/logic/utilities';
import { ApiService } from '@cogent/client/api';
import { ViewCoverageDialogComponent } from '@cogent/client/shared/components/plans-and-coverage/view-coverage/view-coverage.component';
import { PolicyOption } from '@upkeeplabs/models/cogent';
import { PlanClient } from '@cogent/client/shared/models/plans/plan-client.model';

class Step {
    description: string;
    hideNextButton: boolean;
    showSkipButton: boolean;
}

@Component({
    selector: 'app-change-coverage',
    templateUrl: './change-coverage.component.html',
    styleUrls: ['./change-coverage.component.css']
})
export class ChangeCoverageComponent implements OnInit {

    plans: any[];
    saving = false;
    selectedPlan: PlanClient;
    selectedIndex = 0;
    policySummary: PolicySummary;
    currentStepDesc: string;
    loading = true;
    numbers = [0, 1, 2, 3, 4, 5, 6];
    baseUrl: string;
    baseUrl2: string;

    args: UpdatePolicyCoverageArgs = new UpdatePolicyCoverageArgs();
    steps: Step[] = [
        { description: 'Plan Selection', hideNextButton: true, showSkipButton: false },
        { description: 'Options', hideNextButton: true, showSkipButton: true, },
        { description: 'Review', hideNextButton: true, showSkipButton: false },
    ];

    constructor(
        private activatedRoute: ActivatedRoute,
        private dialog: MatDialog,
        private policyApi: PolicyApiService,
        private missionService: MissionService,
        private api: ApiService,
        private planApi: PlanApiService) { }

    ngOnInit(): void {
        
        this.api.getApiEndpointDotNet().then(url => this.baseUrl = url);
        this.api.getApiEndpointNode().then(url2 => this.baseUrl2 = url2);
        this.currentStepDesc = this.steps[0].description;
        this.activatedRoute.params.subscribe(params => {
            if (params.id) {
                this.policyApi.getPolicySummary(params.id).then(ps => {
                    this.policySummary = ps;
                    this.planApi.getPlansForPolicy(params.id).then(plans => {
                        this.plans = plans;
                        this.selectedPlan = this.plans.find(i => i.id === this.policySummary.planId);
                        this.loading = false;
                    });
                });
            }
        });
    }

    selectedPlanChange(plan: PlanClient) {
        this.selectedIndex = 1;
    }

    get lastStep() {
        return this.selectedIndex === this.totalSteps - 1;
    }

    optionSelected(option: SalesItemModel) {
        if (option.selected && option.quantity === 0) {
            option.quantity = 1;
        }
        if (!option.selected) {
            option.quantity = 0;
        }
    }

    showContractLanguage(option) {
        this.dialog.open(ViewCoverageDialogComponent, { data: option.planItemId });
    }


    get planBasePrice(): number {
        if (!this.selectedPlan) {
            return 0;
        }

        let amount = 0;

        amount = this.selectedPlan.price;
        this.selectedPlan.optionalItems.forEach(optionalItem => {
            if (optionalItem.isDefault) {
                amount -= optionalItem.price;
            }
        });

        return amount;
        // return this.newPolicy.policy.isMonthly ? (this.selectedPlan.priceRecurring) : this.selectedPlan.price;
    }

    get totalPriceDisplay(): number {
        return this.totalPrice;
    }

    get adjustmentAmount() {
        // TODO: may need to allow for adjustments/coupon codes
        return 0;
    }

    get totalPrice(): number {
        if (!this.selectedPlan) {
            return 0;
        }

        return this.planBasePrice + // this.quote.selectedPlan.price +
            (this.selectedOptions && this.selectedOptions.length > 0 ? this.selectedOptions.map(i => i.price).reduce((a, b) => a + b) : 0)
            + this.adjustmentAmount;
    }

    get selectedOptions(): SalesItemModel[] {
        if (!this.selectedPlan || !this.selectedPlan.optionalItems) {
            return null;
        }

        return this.selectedPlan.optionalItems.filter(i => i.selected);
    }

    @HostListener('window:hashchange', ['$event'])
    watchUrlHash() {
        if (this.selectedIndex === 3) {
            return;
        }
        let hash = window.location.hash;
        if (hash) {
            hash = hash.replace('#', '');
        }
        let hashNumber = parseInt(hash, 10);
        if (isNaN(hashNumber)) {
            hashNumber = 0;
        }
        if (!isNaN(hashNumber)) {
            this.selectedIndex = hashNumber;
        }
    }

    selectedIndexChange(index: number) {
        document.location.hash = index.toString();
        this.currentStepDesc = null;
        setTimeout(() => this.currentStepDesc = this.steps[this.selectedIndex].description);
        if (index === 0 && this.plans) {
            const plans = this.plans;
            this.plans = null;
            setTimeout(() => this.plans = plans, 10);
        }
    }

    get totalSteps() {
        return this.steps.length;
    }

    get pct() {
        return ((this.selectedIndex + 1) / this.totalSteps) * 100;
    }

    get hideNextButton() {
        const current = this.steps[this.selectedIndex];
        if (current) {
            return current.hideNextButton;
        }
    }

    get showSkipButton() {
        const current = this.steps[this.selectedIndex];
        if (current) {
            return current.showSkipButton;
        }
    }


    get nextStep() {
        const next = this.steps[this.selectedIndex + 1];
        if (next) {
            return next.description;
        }
    }

    

    get contractUrl() {
        if (!this.policySummary || !this.baseUrl) {
            return null;
        }

        return `${this.baseUrl2}policy/contract/pdf/${this.policySummary.id}`;
    }
    get invoiceUrl() {
        if (!this.policySummary || !this.baseUrl) {
            return null;
        }

        return `${this.baseUrl}policy/${this.policySummary.id}/title-invoice/pdf`;

    }

    get certificateUrl() {
        if (!this.policySummary || !this.baseUrl) {
            return null;
        }

        return `${this.baseUrl}policy/${this.policySummary.id}/certificate/pdf?showContent=true`;

    }


    get canSave() {
        return this.selectedPlan && !this.saving;
    }

    async save() {
        this.saving = true;

        this.args.policyId = this.policySummary.id;
        this.args.planId = this.selectedPlan.id;
        this.args.options = [];
        for (const option of this.selectedPlan.optionalItems.filter(i => i.selected)) {
            for (let i = 0; i < option.quantity; i++) {
                const policyOption = new PolicyOption();
                policyOption.id = UtilitiesService.newid();
                policyOption.name = option.name;
                policyOption.planItemId = option.planItemId;
                policyOption.policyId = this.policySummary.id;

                this.args.options.push(policyOption);
            }
        }

        await this.policyApi.savePortalPolicyCoverage(this.args);

        this.saving = false;
        this.selectedIndex = 3;
    }
}
