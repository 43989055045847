<div id="first-section" class="">
    <div class="container">

        <h1 class="page-title white">
            <span class="large-print">Password</span><br><span class="little">Reset</span>
        </h1>

        <mat-card topmargin30>

            <mat-tab-group class="hide-tabs" topmargin30 [(selectedIndex)]="selectedIndex">
                <mat-tab label="First">
                    <form>
                        <mat-form-field appearance="outline" style="max-width: 450px;">
                            <mat-label>Email Address</mat-label>
                            <input name="txtEmail" matInput type="email" [(ngModel)]="emailAddress">
                        </mat-form-field>

                        <button mat-button mat-raised-button color="primary" large [disabled]="!canSend || sending"
                            (click)="send()">
                            <mat-spinner *ngIf="sending" class="thirty"></mat-spinner>
                            Send
                        </button>
                    </form>
                </mat-tab>
                <mat-tab label="Second">
                    <mat-form-field appearance="outline" style="max-width: 450px;">
                        <mat-label>Verification Code</mat-label>
                        <input matInput type="number" [(ngModel)]="code">
                    </mat-form-field>

                    <mat-form-field appearance="outline" style="max-width: 450px">
                        <mat-label>New Password</mat-label>
                        <input matInput type="password" [(ngModel)]="newPassword">
                    </mat-form-field>

                    <mat-form-field appearance="outline" style="max-width: 450px">
                        <mat-label>Confirm New Password</mat-label>
                        <input matInput type="password" [(ngModel)]="newPasswordConfirm">
                    </mat-form-field>

                    <button mat-button (click)="selectedIndex = 0">
                        <mat-icon>chevron_left</mat-icon> Back
                    </button>
                    <button mat-button mat-raised-button color="primary" large [disabled]="!canReset || resetting"
                        (click)="reset()">
                        <mat-spinner *ngIf="resetting" class="thirty"></mat-spinner>
                        Reset
                    </button>
                </mat-tab>
                <mat-tab label="Third">
                    <div class="center">
                        <app-check-circle-animated></app-check-circle-animated>
                    </div>
                    <div style="text-align: center;">
                        <h1 topmargin40>Password Reset</h1>
                        <p style="font-style: italic;">You should now be able to log in with your new password.</p>


                        <a topmargin30 mat-button mat-raised-button color="primary" large routerLink="/">Log In</a>
                    </div>
                </mat-tab>
            </mat-tab-group>
        </mat-card>
    </div>
</div>