import { Component, OnInit, Inject } from '@angular/core';
import { SalesApiService } from '@cogent/client/shared/services/api/sales-api.service';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { EntityApiService } from "@cogent/client/shared/services/api/entity-api.service";
import { UtilitiesService } from '@cogent/client/shared/logic/utilities';
import { MissionService } from '@cogent/client/shared/services/mission-service';
import { Quote } from '@upkeeplabs/models/cogent';

@Component({
    selector: 'app-send-quote-dialog',
    templateUrl: './send-quote-dialog.component.html',
    styleUrls: ['./send-quote-dialog.component.css']
})
export class SendQuoteDialogComponent implements OnInit {

    email: string;
    sending = false;
    constructor(private salesApi: SalesApiService,
        public dialogRef: MatDialogRef<SendQuoteDialogComponent>,
        private missionService: MissionService,
        private entityApi: EntityApiService,
        @Inject(MAT_DIALOG_DATA) public data: Quote) { }

    ngOnInit() {
        this.entityApi.getLoggedInUser().then(user => {
            this.email = user.email;
        });
    }

    get canSend() {
        return this.email
            && UtilitiesService.validateEmail(this.email);
    }

    async send() {
        this.sending = true;
        await this.salesApi.emailQuote(this.data.id, this.email);
        this.sending = false;
        this.dialogRef.close(true);
        this.missionService.showSuccessToast('Quote Sent');
    }

}
