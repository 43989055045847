<div mat-dialog-content>
  <div flex>
    <div>
      <h1 class="sub-heading">Edit Entity</h1>
    </div>
    <div nogrow>
      <button mat-button matDialogClose>
        <i class="material-icons">close</i>
      </button>
    </div>
  </div>
  
  <app-basic-entity-entry [showPhoneNumberEntry]="true" topmargin30 [nameIsSearchBox]="false" [(canSave)]="canSave" [entity]="entity"
    style="display: block"></app-basic-entity-entry>


  </div>

  <div mat-dialog-actions flex>

    <div>
        <a matDialogClose *ngIf="entity" (click)="closeDialog()" mat-button routerLink="entity/real-estate-edit/{{entity.id}}">More Edit Options</a>
    </div>
    <div nogrow>
        <button mat-button color="primary" mat-raised-button [disabled]="!canSave || saving" (click)="saveEntity()">
            <mat-spinner *ngIf="saving" class="thirty"></mat-spinner> Save</button>
    </div>
  </div>
