<div id="first-section" class="">


    <div flex id="welcome-container">
        <div style="min-height: 120px;">
            <div class="fuzzy-container fuzzy" style="min-height: unset;display: inline-block;">
                <h1 *ngIf="loggedInUser" class="white-text" id="welcome-message">
                    <span class="small">Welcome</span><br>
                    {{loggedInUser.name}}
                </h1>
            </div>
        </div>
        <div nogrow class="white-text fuzzy-container fuzzy" style="min-height: unset;">
            <app-doughnut [metrics]="mappedExpenses"></app-doughnut>
            <div style="min-height: 40px;" *ngIf="noExpenses">
                <div *ngIf="noExpenses" class="slide-in-up center no-expenses">
                    No client savings yet.<br> But this is what the average customer can save.
                </div>
            </div>
        </div>
    </div>

    <div class="container">

        <a mat-button mat-raised-button routerLink="/new-order" style="display: table; margin: 0 auto;min-width: 175px;"
            topmargin40 color="primary" mobile-block>New
            Order</a>

        <div flex style="margin-top: 70px">

            <div flex1 class="fuzzy-container fuzzy">

                <div class="center" style="display: flex;flex-direction: column;height: 100%;">
                    <div style="flex: 1;" topmargin40>
                        <h1 class="big-data">{{policyCount}}</h1>
                        <div class="data-label">Client Subscriptions</div>
                    </div>
                    <div aflex style="flex: none">
                        <div></div>
                        <div nogrow>
                            <a mat-button (click)="scrollToFragment('#my-quotes')">
                                More <mat-icon>chevron_right</mat-icon>
                            </a>
                        </div>
                    </div>


                </div>

            </div>
            <div flex1 class="fuzzy-container fuzzy">

                <h2>Account Executive</h2>
                <div class="center">
                    <img *ngIf="aePhotoURL" topmargin30 style="height: 120px;border-radius: 100%;" [src]="aePhotoURL">
                    <div *ngIf="accountExecutive">
                        <h2>{{accountExecutive.name}}</h2>
                        <div>
                            <a href="mailto:{{accountExecutive.email}}">{{accountExecutive.email}}
                            </a>
                            <br>
                            <span *ngIf="accountExecutive.mobileNumber">
                                <a
                                    href="tel:{{accountExecutive.mobileNumber.number}}">{{accountExecutive.mobileNumber.number}}</a>
                            </span>
                        </div>
                    </div>
                </div>
            </div>
            <div flex1 class="fuzzy-container fuzzy">
                <div class="center" style="display: flex;flex-direction: column;height: 100%;">
                    <div style="flex: 1;">
                    </div>
                    <div style="flex: none">
                        <div class="star-ratings-sprite"><span id="rating" class="star-ratings-sprite-rating"
                                [style.width]="ratingPercent"></span></div>
                        <div class="data-label" *ngIf="!noRatings">Average Customer Rating</div>
                        <div *ngIf="!noRatings">Base on {{surveyCount}} survey(s)</div>
                        <div *ngIf="noRatings">There are no customer ratings yet</div>
                    </div>
                    <div style="flex: 1;"></div>
                </div>
            </div>
        </div>

        <div class="fuzzy-container fuzzy">
            <h2>Resources &amp; Documents</h2>
            <div topmargin30 style="text-align: center">

                <a mat-button *ngFor="let resource of resources" class="document-button"
                    [href]="getResourceUrl(resource)" target="_blank">
                    <img [src]="getResourceThumbnail(resource)">
                    <br>
                    {{resource.documentName}}
                </a>

                <a mat-button class="document-button" [href]="moveInChecklistUrl" target="_blank">
                    <img style="height: 120px;" src="https://elevateh.blob.core.windows.net/cdn/images/real-estate-portal/marketing-checklist.png"><br>
                    Move-In Checklist
                </a>


            </div>
        </div>
    </div>
</div>

<div id="my-quotes" class="soft-dark-bg" style="min-height: 500px;" padding>
    <div class="container">
        <h2 topmargin30 class="sub-heading center">Client <span class="orange">Subscriptions</span></h2>

        <div style="max-width: 350px;" topmargin40>
            <mat-form-field appearance="outline">
                <mat-label>
                    <mat-icon>filter_alt</mat-icon> Filter
                </mat-label>
                <input matInput [(ngModel)]="filter">
            </mat-form-field>
        </div>
        <div *ngIf="policies && policies.length < 700">
            <div class="property-container col-md-4 col-sm-12 soft-dark" *ngFor="let policy of filteredPolicies">
                <div style="display: flex;flex-direction: column;height: 100%;">
                    <div style="flex: 1">
                        <span class="badge">{{policy.status}}</span>
                        <img src="{{baseUrl}}policy/{{policy.id}}/StreetView?width=150&height=150" />
                        <h2 topmargin30>{{policy.holder.name}}</h2>
                        <p>{{policy.propertyAddress.address1}}</p>
                        <p>{{policy.propertyAddress.city}}, {{policy.propertyAddress.state}}
                            {{policy.propertyAddress.postalCode}}</p>
                        <p>{{policy.planName}} ({{policy.effectiveDate | date:'mediumDate'}} - {{policy.expirationDate|
                            date:'mediumDate'}})</p>
                    </div>
                    <div style="flex:none" flex>
                        <div></div>
                        <div nogrow>
                            <a mat-button routerLink="/view-subscription/{{policy.id}}">Manage
                                <mat-icon>chevron_right</mat-icon>
                            </a>
                        </div>

                    </div>
                </div>
            </div>
        </div>
        <div *ngIf="policies && policies.length >= 700">
            <div class="policy-list-view-item" *ngFor="let policy of filteredPolicies"
                routerLink="/view-subscription/{{policy.id}}">
                <div aflex>
                    <div nogrow>
                        <img style="height: 75px;"
                            src="{{baseUrl}}policy/{{policy.id}}/StreetView?width=150&height=150" />
                    </div>
                    <div>
                        <h3>{{policy.holder.name}}</h3>
                        <div>{{policy.propertyAddress.address1}}</div>

                        <p>{{policy.planName}}
                            <span class="badge">{{policy.status}}</span>
                        </p>
                    </div>
                </div>
            </div>
        </div>

        <!-- <div topmargin30></div>

        <div *ngIf="quotes && quotes.length === 0" style="text-align: center">
            <img src="../../../assets/images/inbox-icon.png" style="height: 100px"><br>
            Looks like you don't have any quotes yet.
            <div topmargin30>
                <a routerLink="/quote-type" mat-button mat-raised-button>Create A Quote</a>
            </div>
        </div>

        <div class="soft-dark padding" *ngIf="quotes && quotes.length">
            <a mat-button topmargin10 *ngFor="let quote of quotes" class="quote" aflex
                routerLink="/view-quote/{{quote.id}}">

                <div aflex>
                    <div nogrow>

                        <img src="{{baseUrl}}address/{{quote.addressId}}/StreetView">
                    </div>

                    <div>
                        {{quote.propertyAddress1}}<br>
                        {{quote.planName}}<br>
                        {{quote.total | currency}}
                    </div>
                    <div nogrow>
                        <mat-icon>
                            chevron_right
                        </mat-icon>
                    </div>
                </div>
            </a>
        </div> -->
    </div>
</div>

<!-- <div>
    <div id="ae-section">
        <div class="container">
            <h1 class="large-print white">Questions?</h1>
            <h2 topmargin20 class="white">Your Account Executive is Here to Help</h2>
        </div>
    </div>

    <div class="container" *ngIf="accountExecutive">
        <div id="ae-info" aflex>
            <div nogrow>
                <img *ngIf="aePhotoURL" [src]="aePhotoURL">
            </div>
            <div>
                <h2 class="white">{{accountExecutive.name}}</h2>
                <div class="white">{{accountExecutive.email}}<br>
                    <span *ngIf="accountExecutive.mobileNumber"> {{accountExecutive.mobileNumber.number}}</span>
                </div>
            </div>
        </div>
    </div>
</div> -->