import { Component, OnInit, OnDestroy, AfterViewInit } from '@angular/core';
import { Location } from '@angular/common';
import { ActivatedRoute, Router } from '@angular/router';
import { SalesApiService } from '@cogent/client/shared/services/api/sales-api.service';
import { SalesItemModel } from '@cogent/shared/models/plans/sales-item.model';
import { MatDialog } from '@angular/material/dialog';
import { SendQuoteDialogComponent } from '../send-quote-dialog/send-quote-dialog.component';
import { ApiService } from '@cogent/client/api';
import { UtilitiesService } from '@cogent/client/shared/logic/utilities';
import { MissionService } from '@cogent/client/shared/services/mission-service';
import { UndoArguments } from '@cogent/client/shared/services/mission-service-args';
import { Quote } from '@upkeeplabs/models/cogent';


@Component({
    selector: 'app-view-quote',
    templateUrl: './view-quote.component.html',
    styleUrls: ['./view-quote.component.css']
})
export class ViewQuoteComponent implements OnInit, OnDestroy, AfterViewInit {

    quote: Quote;
    baseUrl = ApiService.endPointDotNet;
    constructor(private location: Location,
        private activatedRoute: ActivatedRoute,
        private salesApi: SalesApiService,
        private missionService: MissionService,
        private router: Router,
        private dialog: MatDialog) { }

    ngOnInit() {
        this.activatedRoute.params.subscribe(params => {
            if (params.id) {
                this.salesApi.getQuote(params.id).then(quote => {
                    this.quote = quote;
                });
            }
        });
    }


    ngOnDestroy(): void {
        UtilitiesService.swipeDetect(document, null);
    }

    ngAfterViewInit(): void {
        setTimeout(() => {
            UtilitiesService.swipeDetect(document, direction => {
                if (direction === 'right') {
                    this.location.back();
                }
            });
        }, 500);
    }

    get selectedOptions(): SalesItemModel[] {
        if (!this.quote || !this.quote.quoteDetailsObject.selectedPlan || !this.quote.quoteDetailsObject.selectedPlan.optionalItems) {
            return null;
        }

        return this.quote.quoteDetailsObject.selectedPlan.optionalItems.filter(i => i.selected);
    }

    get planBasePrice(): number {
        if (!this.quote.quoteDetailsObject.selectedPlan) {
            return 0;
        }

        let amount = 0;

        amount = this.quote.quoteDetailsObject.selectedPlan.price;
        this.quote.quoteDetailsObject.selectedPlan.optionalItems.forEach(optionalItem => {
            if (optionalItem.isDefault) {
                amount -= optionalItem.price;
            }
        });

        return amount;
        // return this.newPolicy.policy.isMonthly ? (this.selectedPlan.priceRecurring) : this.selectedPlan.price;
    }

    get totalPrice(): number {
        if (!this.quote || !this.quote.quoteDetailsObject.selectedPlan) {
            return 0;
        }

        return this.quote.quoteDetailsObject.selectedPlan.price +
            (this.selectedOptions && this.selectedOptions.length > 0 ? this.selectedOptions.map(i => i.price).reduce((a, b) => a + b) : 0);
    }

    async deleteQuote() {
        await this.salesApi.deleteQuote(this.quote.id);
        this.missionService.showUndo(new UndoArguments(() => {
            this.salesApi.unDeleteQuote(this.quote.id).then(() => {
                this.router.navigateByUrl(`/view-quote/${this.quote.id}`);
            });
        }, 'Quote Deleted'));

        this.router.navigateByUrl('/home');
    }

    sendQuote() {
        this.dialog.open(SendQuoteDialogComponent, { data: this.quote });
    }

}
