import { Component, OnInit } from '@angular/core';
import { DomSanitizer } from '@angular/platform-browser';
import { SettingsApiService } from '@cogent/client/shared/services/api/settings-api.service';
import { EntityApiService } from "@cogent/client/shared/services/api/entity-api.service";

@Component({
    selector: 'app-new-order-frame',
    templateUrl: './new-order-frame.component.html',
    styleUrls: ['./new-order-frame.component.css']
})
export class NewOrderFrameComponent implements OnInit {

    orderUrl = '';
    safeOrderUrl: any;
    constructor(private entityApi: EntityApiService,
        private sanitizer: DomSanitizer,
        private settingsApi: SettingsApiService) { }

    async ngOnInit() {
        let url = await this.settingsApi.getCustomerPortalBaseUrl() as string;
        const user = await this.entityApi.getLoggedInUser();
        if (document.location.host === 'localhost:4203') {
            this.orderUrl = `http://localhost:4202/new-order?entityId=${user.id}&embedded=true`;
        } else {
            this.orderUrl = `${url}new-order?entityId=${user.id}&&embedded=true`;
        }
        this.safeOrderUrl = this.sanitizer.bypassSecurityTrustResourceUrl(this.orderUrl);
    }

}
