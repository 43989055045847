import { Component, Inject, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { StripeApiService } from '@cogent/client/shared/services/api/stripe-api.service';
import { DialogsService } from '@cogent/client/shared/services/dialog-service/dialog.service';
import { PolicySummary } from '@cogent/client/shared/models/policies/policy-summary.model';
import { UtilitiesService } from '@cogent/client/shared/logic/utilities';
import { ApiService } from '@cogent/client/api';
import { EntityApiService } from "@cogent/client/shared/services/api/entity-api.service";
import { InvoiceSummary, InvoiceToPay, Payment, StripeCard, StripeTransactionCharge } from '@upkeeplabs/models/cogent';

export class TakePaymentInputArgs {
    policySummary: PolicySummary;
    invoices: InvoiceSummary[];
}

@Component({
    selector: 'app-take-payment',
    templateUrl: './take-payment.component.html',
    styleUrls: ['./take-payment.component.css']
})
export class TakePaymentComponent implements OnInit {
    selectedCard: StripeCard;
    customerId: string;
    newCardButtonText = 'Save';
    paymentAmount = 0;
    invoices: InvoiceSummary[];
    saving = false;
    selectedIndex = 0;
    endpoint: string;
    payment: Payment;
    paymentId: string;
    fullOrPart = 'full';

    constructor(@Inject(MAT_DIALOG_DATA) public data: TakePaymentInputArgs,
        private entityApi: EntityApiService,
        private stripeApi: StripeApiService,
        private dialogService: DialogsService,
        private api: ApiService,
        public dialogRef: MatDialogRef<TakePaymentComponent>) { }

    ngOnInit(): void {
        this.invoices = this.data.invoices;
        this.entityApi.getLoggedInUser().then(user => {
            this.customerId = user.id;
        });
        this.paymentAmount = this.amountDue;
        this.api.getApiEndpointDotNet().then(endpoint => this.endpoint = endpoint);
    }

    get policySummary() {
        return this.data.policySummary;
    }



    get amountDue() {
        if (!this.invoices || this.invoices.filter(i => i.invoiceType === 'Premium' && i.amountDue > 0).length === 0) {
            return 0;
        }

        return this.invoices.filter(i => i.invoiceType === 'Premium' && i.amountDue > 0).map(i => i.amountDue).reduce((a, b) => a + b);
    }

    paymentChanged(type: string) {
        if (type === 'full') {
            this.paymentAmount = this.amountDue;
        } else {
            this.paymentAmount = null;
        }
    }

    get canSave() {
        return this.selectedCard
            && !this.saving
            && this.paymentAmount > 0
            && this.paymentAmount <= this.amountDue;
    }

    async save() {
        this.saving = true;


        const stripePayment = new StripeTransactionCharge();
        stripePayment.amount = this.paymentAmount;
        stripePayment.stripeCardId = this.selectedCard.id;
        stripePayment.stripeCustomerId = this.selectedCard.customer;
        if (this.policySummary) {
            stripePayment.policyId = this.policySummary.id;
        }

        const invoices = this.invoices.filter(i => i.invoiceType === 'Premium' && i.amountDue > 0).map(i => {
            const invoiceToPay = new InvoiceToPay();
            invoiceToPay.invoiceId = i.id;
            invoiceToPay.amount = 0;
            invoiceToPay.totalInvoiceAmount = i.amountDue;

            return invoiceToPay;
        });

        let amountLeft = this.paymentAmount;
        while (amountLeft > 0) {
            const invoice = invoices.find(i => i.totalInvoiceAmount > 0);
            if (!invoice) {

                break;
            }
            if (invoice.totalInvoiceAmount > amountLeft) {
                invoice.amount = amountLeft;
                amountLeft = 0;
                invoice.totalInvoiceAmount -= amountLeft;
            } else {
                invoice.amount = invoice.totalInvoiceAmount;
                amountLeft -= invoice.amount;
                invoice.totalInvoiceAmount = 0;
            }
        }


        stripePayment.invoicesToPay = invoices;



        const payment = await this.stripeApi.createChargeFullReturn(this.customerId, stripePayment, async errors => {
            this.saving = false;
            if (errors && errors.length > 0) {
                this.dialogService.alert('Not Processed', errors[0].message);

                return;
            }
        });
        this.payment = payment;
        this.paymentId = payment.paymentItems[0].paymentId;

        if (!this.policySummary.effectiveDate && this.policySummary.coverageType === 'RealEstate') {

            const effectiveDate = new Date();

            this.policySummary.effectiveDate = UtilitiesService.dayBegin(effectiveDate);
            const expirationDate = new Date(effectiveDate);
            expirationDate.setMonth(expirationDate.getMonth() + this.policySummary.monthsOfCoverage);
            this.policySummary.expirationDate = expirationDate;

            await this.api.patchDotNet(`Policy/${this.policySummary.id}`, { effectiveDate: this.policySummary.effectiveDate });
        }

        this.saving = false;
        this.selectedIndex = 1;
    }

}
