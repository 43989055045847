<div class="container" *ngIf="quote">
    <div class="padding">
        <h1 class="white page-title"><span class="large-print">Your</span><br><span class="little">Quote</span></h1>
    </div>

    <div id="property-info" aflex padding>

        <div nogrow>
            <img src="{{baseUrl}}address/{{quote.addressId}}/StreetView">
        </div>
        <div>
            {{quote.quoteDetailsObject.address.address1}}<br>
            {{quote.quoteDetailsObject.address.city}}, {{quote.quoteDetailsObject.address.state}}
            {{quote.quoteDetailsObject.address.postalCode}}
        </div>
    </div>

    <div>
        <div class="padding" topmargin40>

            <a mat-button mat-raised-button routerLink="/order/{{quote.id}}" color="primary" large mobile-block
                style="display: table; margin: 0 auto">Order Now</a>
        </div>


        <div class="gray padding" topmargin30 style="max-width: 600px">
            <div aflex>
                <div nogrow>
                    <h3>{{quote.quoteDetailsObject.selectedPlan.name}}</h3>
                </div>
                <div class="dotted"></div>
                <div nogrow>{{planBasePrice | currency}}</div>
            </div>
            <div aflex *ngFor="let option of selectedOptions">

                <div nogrow class="indent plan-item-field">
                    {{option.name}}

                    <span *ngIf="option.quantity > 1"> (x{{option.quantity}})</span>
                </div>
                <div class="dotted"></div>
                <div nogrow>
                    {{option.quantity * option.price | currency}}
                </div>
            </div>
            <div topmargin30 aflex>
                <div></div>
                <div nogrow>
                    <div class="data-label right">Total Price</div>
                    <h1 class="big-data">{{totalPrice | currency}}</h1>
                </div>
            </div>
        </div>

        <div class="padding">
            <div class="data-label" topmargin30 bottomargin10>Additional Options</div>

            <button mat-button (click)="deleteQuote()">
                <mat-icon>close</mat-icon><br>
                Delete
            </button>
            <a style="vertical-align: bottom"
                href="{{baseUrl}}Plan/{{quote.quoteDetailsObject.selectedPlan.id}}/contract/pdf" mat-button
                target="_blank">
                <mat-icon>description</mat-icon><br>
                Sample Contract
            </a>
            <button mat-button (click)="sendQuote()">
                <mat-icon>mail</mat-icon>
                <br>Send
            </button>
        </div>
    </div>
</div>
