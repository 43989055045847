<div class="container">
    <mat-tab-group [(selectedIndex)]="selectedIndex" class="hide-tabs overflow-visible"
        (selectedIndexChange)="selectedIndexChange($event)">
        <mat-tab label="First">
            <div flex class="padding">
                <div>
                    <h1 class="page-title">
                        <span class="large-print">Property</span><br>
                        <span class="little">Info</span>
                    </h1>
                </div>
                <div nogrow>
                    <button mobile-block class="mobile-top-20" large [disabled]="!addressComplete || loadingPlans"
                        mat-button mat-raised-button color="primary" (click)="loadPlans()">
                        <mat-spinner *ngIf="loadingPlans" class="thirty"></mat-spinner>
                        Next <mat-icon *ngIf="!loadingPlans">chevron_right
                        </mat-icon>
                    </button>
                </div>

            </div>
            <div id="property-info-container" class="soft-light padding">
                <div flex>
                    <div nogrow id="property-icon-container">
                        <img src="../../../assets/images/house.svg">
                    </div>
                    <div>
                        <app-address-editor (addressCompleteChange)="addressCompleteChange($event)"
                            [address]="quote.address">
                        </app-address-editor>
                    </div>
                </div>
            </div>

            <div flex class="padding">
                <div> </div>
                <div nogrow>
                    <button mobile-block class="mobile-top-20"  [disabled]="!addressComplete || loadingPlans"
                        mat-button mat-raised-button color="primary" (click)="loadPlans()">
                        <mat-spinner *ngIf="loadingPlans" class="thirty"></mat-spinner>
                        Next <mat-icon *ngIf="!loadingPlans">chevron_right
                        </mat-icon>
                    </button>
                </div>

            </div>

        </mat-tab>
        <mat-tab label="Second">
            <div id="plan-selection-container">

                <div class="padding">
                    <h1 class="page-title white">
                        <span class="large-print">Plan</span><br>
                        <span class="little">Selection</span>
                    </h1>
                </div>
                <div id="plan-selection-container1" [class.shift-1-space]="planIndex === 1"
                    [class.shift-2-space]="planIndex === 2">
                    <div *ngFor="let plan of plans; let i = index" id="plan-{{i}}" [class.active-plan]="i === planIndex"
                        [class.closing]="closingDetails">

                        <div class="flip-container" [class.flip-it]="plan.itemsExpanded">
                            <div class="flipper">
                                <div class="front"
                                    [style.background-image]="getBackground(plan.planStyleObject ? plan.planStyleObject.cardFrontBackgroundImage : null)">

                                    <div class="ribbon" *ngIf="plan.planStyleObject && plan.planStyleObject.bannerText">
                                        <span>{{plan.planStyleObject.bannerText}}</span></div>
                                    <div class="flex-container-vert" *ngIf="!plan.itemsExpanded">
                                        <div class="first-child">

                                            <h1>{{plan.name}}</h1>
                                            <div class="cost-container">
                                                Starting At:<br>
                                                <h2 style="color: #fff;"><span class="big-data">
                                                        {{plan.price | currency : 'USD':'symbol-narrow':'1.0-2' }}</span>/year
                                                </h2>
                                            </div>

                                            <button mat-button block topmargin20 mat-raised-button large
                                                (click)="orderPlan(plan, i)"
                                                color="{{plan.planStyleObject ? plan.planStyleObject.buttonColor : ''}}">Select
                                                Plan</button>
                                        </div>
                                        <div class="no-grow">

                                            <div class="additional-info">
                                                <p>
                                                    {{plan.summary}}
                                                </p>
                                                <button mat-button block class="more-button" (click)="selectPlan(i)"><i
                                                        class="material-icons">keyboard_arrow_down</i> See More</button>
                                                <button mat-button block class="more-button-mobile"
                                                    (click)="plan.itemsExpanded = !plan.itemsExpanded">
                                                    Details <i class="material-icons">chevron_right</i>
                                                </button>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div class="back"
                                    [style.background-image]="getBackground(plan.planStyleObject ? plan.planStyleObject.cardBackBackgroundImage : null)">

                                    <div class="items-container">


                                        <button mat-button class="close-detail-button"
                                            (click)="plan.itemsExpanded = false">
                                            <mat-icon>close</mat-icon>
                                            <br>
                                            Close
                                        </button>

                                        <h3 style="color: #fff;">{{plan.name}} Standard Items</h3>
                                        <ul>
                                            <li *ngFor="let item of plan.standardItems">
                                                {{item.name}}
                                            </li>
                                        </ul>

                                        <a target="_blank" style="text-align: center" block mat-button
                                            [href]="getSampleContractUrl(plan)">
                                            <mat-icon>description</mat-icon>
                                            Sample Contract
                                        </a>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                <button mat-button block id="show-compare" *ngIf="!comparePlansVisible" (click)="showCompareClicked()">
                    <mat-icon>done_all</mat-icon> Compare Plans
                </button>

                <div id="plan-index-indicator">

                    <div *ngFor="let plan of plans" (click)="planIndex = plans.indexOf(plan)"
                        [class.selector-1]="plans.indexOf(plan) === 0" [class.selector-2]="plans.indexOf(plan) === 1"
                        [class.selector-3]="plans.indexOf(plan) === 2"
                        [class.selected]="plans.indexOf(plan) === planIndex"></div>
                </div>

            </div>



            <div id="plan-details" class="no-display" style="position: relative">
                <div id="plan-details-icon">
                    <img src="../../../assets/images/hvac-icon.png">
                </div>
                <button mat-button id="close-button" style="position: absolute; top: 10px; right: 10px"
                    (click)="closeButtonClicked()">
                    <mat-icon>close</mat-icon> Close
                </button>

                <app-plan-selection [(useMonthly)]="useMonthly" [highlightedPlan]="highlightedPlan" [plans]="plans"
                    (selectedPlanChange)="selectedPlanChange($event)"></app-plan-selection>

            </div>

        </mat-tab>
        <mat-tab label="Third">
            <div flex class="padding" bottommargin30>
                <div>
                    <h1 class="page-title white">
                        <span class="large-print">Options</span><br>
                        <span class="little">Selection</span>
                    </h1>
                </div>
                <div nogrow>
                    <button mat-button mat-raised-button class="mobile-top-20" color="primary"
                        (click)="selectedIndex = 3" mobile-block large>Next

                        <mat-icon>chevron_right</mat-icon>
                    </button>
                </div>

            </div>
            <div *ngIf="quote && quote.selectedPlan" class="padding">

                <div id="mobile-options">
                    <div class="mobile-option" aflex *ngFor="let option of quote.selectedPlan.optionalItems">

                        <div nogrow>
                            <mat-checkbox [(ngModel)]="option.selected" (change)="optionSelected(option)">
                            </mat-checkbox>
                        </div>
                        <div>
                            <h2 (click)="option.selected = !option.selected; optionSelected(option);">{{option.name}}
                            </h2>
                            <div>

                            </div>
                            <div>
                                <div topmargin10 *ngIf="option.selected && !option.allowQuantity"><span
                                        class="data-label">Quantity:
                                    </span> 1</div>
                                <div aflex *ngIf="option.selected && option.allowQuantity" topmargin10>
                                    <div nogrow class="data-label">Quantity: </div>
                                    <div>

                                        <mat-form-field appearance="outline" style="margin-top: -20px; width: 100px !important">
                                            <mat-select [(ngModel)]="option.quantity">
                                                <mat-option *ngFor="let number of numbers" [value]="number">
                                                    {{ number }}
                                                </mat-option>
                                            </mat-select>
                                        </mat-form-field>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div nogrow>
                            <span *ngIf="!useMonthly">{{option.price| currency:'USD':'symbol':'1.2-2'}}</span>
                            <span *ngIf="useMonthly">{{option.priceRecurring| currency:'USD':'symbol':'1.2-2'}}</span>
                        </div>
                        <div nogrow>
                            <button mat-button (click)="showContractLanguage(option)">
                                <mat-icon>search</mat-icon>
                            </button>
                        </div>
                    </div>
                </div>


                <div class="table soft-light" id="desktop-options">
                    <div class="table-header">
                        <div flex3>Item</div>
                        <div flex1>Price</div>
                        <div flex1>Selected</div>
                        <div flex1>Qty</div>
                        <div nogrow style="width: 60px"></div>
                    </div>
                    <div class="table-row" *ngFor="let option of quote.selectedPlan.optionalItems">
                        <div flex3>{{option.name}}</div>
                        <div flex1>
                            <span *ngIf="!useMonthly">{{option.price| currency:'USD':'symbol':'1.2-2'}}</span>
                            <span *ngIf="useMonthly">{{option.priceRecurring| currency:'USD':'symbol':'1.2-2'}}</span>
                        </div>
                        <div flex1>
                            <mat-checkbox [(ngModel)]="option.selected" (change)="optionSelected(option)">
                            </mat-checkbox>

                        </div>
                        <div flex1>
                            <span *ngIf="option.selected && !option.allowQuantity">
                                1</span>
                            <div style="margin-top: -5px" *ngIf="option.selected && option.allowQuantity">
                                <mat-form-field appearance="outline">
                                    <mat-select [(ngModel)]="option.quantity">
                                        <mat-option *ngFor="let number of numbers" [value]="number">
                                            {{ number }}
                                        </mat-option>
                                    </mat-select>
                                </mat-form-field>
                            </div>
                        </div>
                        <div nogrow style="width: 60px">
                            <button mat-button (click)="showContractLanguage(option)">
                                <mat-icon>search</mat-icon>
                            </button>
                        </div>
                    </div>
                </div>
            </div>

            <div flex class="padding" bottommargin30>
                <div>
                </div>
                <div nogrow>
                    <button mat-button mat-raised-button class="mobile-top-20" color="primary"
                        (click)="selectedIndex = 3" mobile-block >Next

                        <mat-icon>chevron_right</mat-icon>
                    </button>
                </div>

            </div>
        </mat-tab>
        <mat-tab>
            <div class="padding">
                <h1 class="page-title white">
                    <span class="large-print">Quote</span><br>
                    <span class="little">Summary</span>
                </h1>

                <div topmargin40 style="display: table; margin: 0 auto">
                    <button mat-button mat-raised-button large color="primary" style="font-size: 18px"
                        (click)="selectedIndex = 4">Order
                        Now</button>
                    <button mat-button mat-raised-button style="margin-left: 20px" style="font-size: 18px" large
                        [disabled]="saving" (click)="saveQuote()">
                        <mat-spinner *ngIf="saving" class="thirty"></mat-spinner>
                        Save Quote
                    </button>
                </div>

                <div id="property-info" aflex padding>

                    <div nogrow>
                        <img [src]="propertyStreetView" *ngIf="selectedIndex === 3" style="height: 75px;">
                    </div>
                    <div *ngIf="quote && quote.address">
                        {{quote.address.address1}}<br>
                        {{quote.address.city}}, {{quote.address.state}} {{quote.address.postalCode}}
                    </div>
                </div>

                <div topmargin30 class="soft-light padding" style="max-width: 600px">
                    <ng-container *ngIf="quote && quote.selectedPlan">
                        <div aflex>
                            <div nogrow>
                                <h3>{{quote.selectedPlan.name}}</h3>
                            </div>
                            <div class="dotted"></div>
                            <div nogrow>{{planBasePrice | currency}}</div>
                        </div>

                        <div aflex *ngFor="let option of selectedOptions">

                            <div nogrow class="indent plan-item-field">
                                {{option.name}}

                                <span *ngIf="option.quantity > 1"> (x{{option.quantity}})</span>
                            </div>
                            <div class="dotted"></div>
                            <div nogrow>
                                {{option.quantity * option.price | currency}}
                            </div>
                        </div>
                        <div *ngIf="priceDifferential" aflex>
                            <div nogrow class="indent plan-item-field">Additional Options</div>
                            <div class="dotted"></div>
                            <div nogrow>{{priceDifferential | currency}}</div>
                        </div>
                    </ng-container>
                    <div topmargin30 aflex>
                        <div></div>
                        <div nogrow>
                            <!-- <div class="data-label right">Total Price</div>
                            <h1 class="big-data">{{totalPriceDisplay | currency}}</h1> -->
                            <app-display-total [total]="totalPriceDisplay" label="Total Price"></app-display-total>
                        </div>
                    </div>
                </div>
            </div>
        </mat-tab>

        <mat-tab>
            <div flex class="padding">
                <div>
                    <h1 class="page-title white" topmargin20>
                        <span class="large-print">Homeowner</span><br>
                        <span class="little">Information</span>
                    </h1>
                </div>
                <div nogrow>
                    <button mat-button large mobile-block mat-raised-button
                        [disabled]="!customerContactInformationValid" color="primary" (click)="selectedIndex = 5"
                        topmargin20>Next

                        <mat-icon>chevron_right</mat-icon>
                    </button>
                </div>
            </div>


            <div class="soft-light padding" style="margin: 5px;margin-top: 65px">
                <mat-form-field appearance="outline">
                    <mat-label>Customer Name *</mat-label>
                    <input matInput  [(ngModel)]="quote.holder.name">
                </mat-form-field>

                <!-- <button mat-button mobile-block (click)="usePropertyAddress()">
                    <mat-icon>file_copy</mat-icon> Use Property Address
                </button> -->
                <mat-form-field appearance="outline">
                    <mat-label>Mailing Address 1 *</mat-label>
                    <input matInput placeholder="Mailing Address 1 *" [(ngModel)]="quote.holder.address.address1">
                    <mat-hint align="end">123 W Oak</mat-hint>
                </mat-form-field>

                <mat-form-field appearance="outline">
                    <mat-label>Mailing Address 2</mat-label>
                    <input matInput [(ngModel)]="quote.holder.address.address2">

                    <mat-hint align="end">Unit 1</mat-hint>
                </mat-form-field>

                <div flex>
                    <div flex3>
                        <mat-form-field appearance="outline">
                            <mat-label>City *</mat-label>
                            <input matInput [(ngModel)]="quote.holder.address.city">

                            <mat-hint align="end">My Town</mat-hint>
                        </mat-form-field>
                    </div>
                    <div flex1>
                        <mat-form-field appearance="outline">
                            <mat-label>State *</mat-label>
                            <input matInput [(ngModel)]="quote.holder.address.state">

                            <mat-hint align="end">NY</mat-hint>
                        </mat-form-field>
                    </div>
                    <div flex1>
                        <mat-form-field appearance="outline">
                            <mat-label>Postal Code *</mat-label>
                            <input matInput [(ngModel)]="quote.holder.address.postalCode">

                            <mat-hint align="end">11111</mat-hint>
                        </mat-form-field>
                    </div>
                </div>

                <mat-form-field appearance="outline">
                    <mat-label>
                        <mat-icon>mail</mat-icon> Email*
                    </mat-label>
                    <input matInput inputmode="email" type="email" [(ngModel)]="quote.holder.email">

                    <mat-hint align="end">email&#64;domain.com</mat-hint>
                </mat-form-field>

                <div aflex>
                    <div flex1>
                        <mat-form-field appearance="outline">
                            <mat-label>
                                <mat-icon>phone</mat-icon> Home Number *
                            </mat-label>
                            <input matInput inputmode="tel" mask="(000) 000-0000" type="tel"
                                [(ngModel)]="quote.holder.homeNumber.number">
                            <mat-hint align="end">(123) 456-7890</mat-hint>
                        </mat-form-field>
                    </div>
                    <div flex1>
                        <mat-form-field appearance="outline">
                            <mat-label>
                                <mat-icon>phone_iphone</mat-icon> Mobile Number *
                            </mat-label>
                            <input matInput inputmode="tel" mask="(000) 000-0000" type="tel"
                                [(ngModel)]="quote.holder.mobileNumber.number">
                            <mat-hint align="end">(123) 456-7890</mat-hint>
                        </mat-form-field>
                    </div>
                </div>

            </div>

            <div flex class="padding">
                <div>

                </div>
                <div nogrow>
                    <button mat-button  mobile-block mat-raised-button
                        [disabled]="!customerContactInformationValid" color="primary" (click)="selectedIndex = 5"
                        topmargin20>Next

                        <mat-icon>chevron_right</mat-icon>
                    </button>
                </div>
            </div>

        </mat-tab>
        <mat-tab>
            <div flex class="padding">
                <div>
                    <h1 class="page-title white" topmargin20>
                        <span class="large-print">Closing</span><br>
                        <span class="little">Information</span>
                    </h1>
                </div>
                <div nogrow>
                    <button mat-button large mobile-block mat-raised-button color="primary" (click)="selectedIndex = 6"
                        topmargin20>Next

                        <mat-icon>chevron_right</mat-icon>
                    </button>
                </div>
            </div>



            <div flex style="margin-top: 65px" class="closing-info-container">
                <div class="soft-light padding" flex1 style="margin: 10px">
                    <h2>Closing Information</h2>
                    <div topmargin20>
                        <mat-form-field appearance="outline">
                            <mat-label>Escrow Number</mat-label>
                            <input matInput [(ngModel)]="quote.escrowNumber">
                        </mat-form-field>
                        <app-date-picker [(date)]="quote.estimatedClosingDate" placeholder="Estimated Closing Date">
                        </app-date-picker>
                        <label class="data-label">Closing Officer</label>
                        <app-basic-entity-summary *ngIf="quote.closingOfficer" style="display: block; margin-top: -20px"
                            [entity]="quote.closingOfficer"></app-basic-entity-summary>
                        <div class="find-box" *ngIf="!quote.closingOfficer">
                            <button mat-button mat-raised-button (click)="findClosingOfficer()">
                                <i class="material-icons">search</i> Find</button>
                        </div>
                        <mat-card-actions *ngIf="quote.closingOfficer">
                            <!-- <button mat-button (click)="editClosingOfficer()">
                        <i class="material-icons">edit</i> Edit</button> -->
                            <button mat-button (click)="clearClosingOfficer()">
                                <i class="material-icons">clear</i> Clear</button>
                        </mat-card-actions>
                    </div>
                </div>
                <div class="soft-light padding" flex1 style="margin: 10px">
                    <mat-card-content>
                        <h2>Buyer's Agent</h2>
                        <div *ngIf="isTransactionCoordinator" topmargin20>
                            <mat-form-field appearance="outline">
                                <mat-label>Agent</mat-label>
                                <mat-select [(ngModel)]="selectedAgent"
                                    (ngModelChange)="agentUpdated($event)">
                                    <mat-option *ngFor="let agent of branchAgents" [value]="agent.id">
                                        {{agent.name}}
                                    </mat-option>
                                </mat-select>
                            </mat-form-field>
                        </div>
                        <div class="find-box" *ngIf="!quote.buyersAgent">
                            <button mat-button mat-raised-button (click)="findBuyersAgent()">
                                <i class="material-icons">search</i> Find</button>
                        </div>
                        <app-basic-entity-summary [entity]="quote.buyersAgent"></app-basic-entity-summary>
                    </mat-card-content>
                    <mat-card-actions *ngIf="quote.buyersAgent">
                        <!-- <button mat-button (click)="editBuyersAgent()">
                      <i class="material-icons">edit</i> Edit</button> -->
                        <button mat-button (click)="clearBuyersAgent()">
                            <i class="material-icons">clear</i> Clear</button>
                    </mat-card-actions>
                </div>
                <div class="soft-light padding" flex1 style="margin: 10px">
                    <mat-card-content>
                        <h2>Seller's Agent</h2>
                        <div class="find-box" *ngIf="!quote.sellersAgent">
                            <button mat-button mat-raised-button (click)="findSellersAgent()">
                                <i class="material-icons">search</i> Find</button>
                        </div>
                        <app-basic-entity-summary [entity]="quote.sellersAgent"></app-basic-entity-summary>
                    </mat-card-content>
                    <mat-card-actions *ngIf="quote.sellersAgent">
                        <!-- <button mat-button (click)="editSellersAgent()">
                      <i class="material-icons">edit</i> Edit</button> -->
                        <button mat-button (click)="clearSellersAgent()">
                            <i class="material-icons">clear</i> Clear</button>
                    </mat-card-actions>
                </div>
            </div>
            <div flex class="padding">
                <div>

                </div>
                <div nogrow>
                    <button mat-button mobile-block mat-raised-button color="primary" (click)="selectedIndex = 6"
                        topmargin20>Next

                        <mat-icon>chevron_right</mat-icon>
                    </button>
                </div>
            </div>

        </mat-tab>
        <mat-tab>
            <div class="padding">
                <h1 class="page-title white" topmargin20>
                    <span class="large-print">Order</span><br>
                    <span class="little">Summary</span>
                </h1>
            </div>

            <div class="container" id="summary-container"
                *ngIf="quote && quote.selectedPlan && quote.holder && quote.address">
                <div aflex topmargin30>
                    <div class="soft-light padding" style="margin: 5px">

                        <div aflex>
                            <div>
                                <h1>{{quote.address.address1}}</h1>
                                {{quote.address.city}}, {{quote.address.state}}
                                {{quote.address.postalCode}}
                            </div>
                            <div nogrow>
                                <img *ngIf="quote.address && quote.address.address1 && selectedIndex > 4"
                                    id="street-view-image-mobile" [src]="propertyStreetView" />
                            </div>
                        </div>

                        <form topmargin30 style="max-width: 500px;">
                            <div aflex>
                                <div>
                                    <mat-form-field appearance="outline">
                                        <mat-label>Promotion Code</mat-label>
                                        <input name="promotion-code-input" matInput
                                            [(ngModel)]="promotionCodeSearch">
                                    </mat-form-field>
                                </div>
                                <div nogrow>
                                    <button mat-button mat-raised-button [disabled]="!promotionCodeSearch"
                                        style="margin-top: 9px;margin-left: -98px;" (click)="findPromotionCode()"
                                        color="primary">Apply</button>
                                </div>
                            </div>


                        </form>

                        <div style="max-width: 500px">
                            <h1 topmargin20>Plan &amp; Options</h1>
                            <div aflex>
                                <div nogrow>
                                    <h3>{{quote.selectedPlan.name}}</h3>
                                </div>
                                <div class="dotted"></div>
                                <div nogrow>{{planBasePrice | currency}}</div>
                            </div>

                            <div aflex *ngFor="let option of selectedOptions">

                                <div nogrow class="indent plan-item-field">
                                    {{option.name}}

                                    <span *ngIf="option.quantity > 1"> (x{{option.quantity}})</span>
                                </div>
                                <div class="dotted"></div>
                                <div nogrow>
                                    {{option.quantity * option.price | currency}}
                                </div>
                            </div>
                            <div *ngIf="priceDifferential" aflex>
                                <div nogrow class="indent plan-item-field">Additional Options</div>
                                <div class="dotted"></div>
                                <div nogrow>{{priceDifferential | currency}}</div>
                            </div>
                            <div *ngIf="promotionCode" aflex class="figure-row indent">
                                <div nogrow class=" plan-item-field">
                                    <button style="min-width: 56px;margin-left: -17px;" mat-button
                                        (click)="promotionCode = null">
                                        <mat-icon>close</mat-icon>
                                    </button>
                                    {{promotionCode.code}} <span *ngIf="promotionCode.description">-
                                        {{promotionCode.description}}</span>
                                </div>
                                <div class="dotted" style="margin-left: 5px;height: 26px;"></div>
                                <div nogrow class="dollar-figure">
                                    {{adjustmentAmount | currency}}
                                </div>

                            </div>

                            <div topmargin30 aflex>
                                <div></div>
                                <div nogrow>
                                    <div class="data-label right">Total Price</div>
                                    <h1 class="big-data">{{totalPriceDisplay | currency}}</h1>
                                </div>
                            </div>
                        </div>
                        <div flex>
                            <div>
                                <h1 topmargin20>Customer</h1>
                                <div *ngIf="quote && quote.holder">
                                    {{quote.holder.name}}
                                    <div *ngIf="quote.holder.address">

                                        <div>
                                            {{quote.holder.address.address1}}
                                        </div>
                                        <div *ngIf="quote.holder.address.address2">
                                            {{quote.holder.address.address2}}</div>
                                        {{quote.holder.address.city}}, {{quote.holder.address.state}}
                                        {{quote.holder.address.postalCode}}
                                    </div>
                                </div>
                            </div>
                            <div *ngIf="quote && quote.buyersAgent">
                                <h1 topmargin20>Buyer's Agent</h1>
                                {{quote.buyersAgent.name}}
                            </div>
                            <div *ngIf="quote && quote.sellersAgent">
                                <h1 topmargin20>Sellers's Agent</h1>
                                {{quote.sellersAgent.name}}
                            </div>
                            <div *ngIf="quote && quote.closingOfficer">
                                <h1 topmargin20>Closing Officer</h1>
                                {{quote.closingOfficer.name}}
                            </div>
                        </div>

                    </div>

                    <div nogrow id="street-view-image-container">
                        <img *ngIf="quote.address && quote.address.address1 && selectedIndex > 4" id="street-view-image"
                            [src]="propertyStreetView" />
                    </div>
                </div>

            </div>

            <div flex topmargin20 class="padding">
                <div nogrow>

                    <button mat-button large mobile-block mat-raised-button class="pull-right" [disabled]="saving"
                        color="primary" (click)="createPolicy()">
                        <mat-spinner thirty *ngIf="saving"></mat-spinner>
                        Place Order
                    </button>
                </div>
            </div>


        </mat-tab>
    </mat-tab-group>
</div>