import { Component, OnInit } from '@angular/core';
import { EntityApiService } from "@cogent/client/shared/services/api/entity-api.service";
import { DialogsService } from '@cogent/client/shared/services/dialog-service/dialog.service';
import { MatDialogRef } from '@angular/material/dialog';

@Component({
    selector: 'app-change-password',
    templateUrl: './change-password.component.html',
    styleUrls: ['./change-password.component.css']
})
export class ChangePasswordComponent implements OnInit {
    oldPassword: string;
    newPassword: string;
    newPasswordConfirm: string;
    changingPassword = false;

    constructor(private entityApi: EntityApiService, private dialogService: DialogsService,
        public dialogRef: MatDialogRef<ChangePasswordComponent>) { }

    ngOnInit() {
    }

    async changePassword() {

        this.changingPassword = true;
        const results = await this.entityApi.changeLocalLoginPassword(this.oldPassword, this.newPassword);

        this.changingPassword = false;

        if (results && !results.success) {
            this.dialogService.alert('Password Not Changed', `Sorry, we could not change your password.<br>${results.message}`);
        } else {
            this.dialogRef.close(true);
        }
    }


    get canChangePassword() {
        return this.oldPassword && this.newPassword && this.newPassword === this.newPasswordConfirm;
    }

}
