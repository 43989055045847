import { Component, HostListener, NgZone, Inject } from '@angular/core';
import { Router, NavigationEnd, ActivatedRoute } from '@angular/router';
import { MatSnackBar } from '@angular/material/snack-bar';
import { ApiFailureComponent } from '@cogent/client/shared/components/api-failure/api-failure.component';
import { DOCUMENT } from '@angular/common';
import { ApiService } from '@cogent/client/api';
import { UtilitiesService } from '@cogent/client/shared/logic/utilities';
import { ToastSuccessComponent } from '@cogent/client/shared/components/toast-success/toast-success.component';
import { MissionService, ShellMenuItemModel } from '@cogent/client/shared/services/mission-service';
declare let ga: any;

@Component({
    selector: 'app-root',
    templateUrl: './app.component.html',
    styleUrls: ['./app.component.css']
})
export class AppComponent {
    title = 'real-estate';

    enteringAnimation = false;
    isScrolled = false;
    menuOpen = false;
    overrideMenuItems: ShellMenuItemModel[];
    isOnline = true;
    scrollPosition = 0;
    isHome = false;
    isSettings = false;
    isOrder = false;
    isIFrame = false;

    defaultMenuItems: ShellMenuItemModel[] = [
        new ShellMenuItemModel('Home', null, null, '/'),
        //new ShellMenuItemModel('Get A Quote', null, null, '/quote-type'),
        // new ShellMenuItemModel('Orders', null, null, '/orders'),
        new ShellMenuItemModel('Order', null, null, '/new-order'),
        new ShellMenuItemModel('Settings', null, null, '/settings'),
        new ShellMenuItemModel('Log Out', null, null, '/auth/log-out'),
    ];

    constructor(private router: Router, private zone: NgZone,
        missionService: MissionService,
        private activatedRoute: ActivatedRoute,
        private snackBar: MatSnackBar,
        @Inject(DOCUMENT) private htmlDocument: HTMLDocument) {
        missionService.overrideMenuItems.subscribe(
            menuItems => {
                this.overrideMenuItems = menuItems;
            });

        missionService.toast.subscribe(message => {
            this.snackBar.open(message, null, { duration: 10000 });
        });

        missionService.toastSuccess.subscribe(message => {
            this.snackBar.openFromComponent(
                ToastSuccessComponent,
                { data: message, duration: 4000, panelClass: ['success-toast'] });
        });

        missionService.apiError.subscribe(parameters => {
            if (this.isOnline) {
                this.snackBar.openFromComponent(
                    ApiFailureComponent,
                    { duration: 10000, data: parameters, panelClass: ['failed-toast'] });
            }
        });

        missionService.undo.subscribe(undoArgs => {
            this.snackBar.open(undoArgs.message, 'UNDO', { duration: undoArgs.timeout })
                .onAction()
                .subscribe(() => { undoArgs.callback(); });
        });


        router.events.subscribe(val => {
            if (val instanceof NavigationEnd) {

                const nav = val as NavigationEnd;
                window.scrollTo(0, 0);

                if (nav && nav.url && nav.url.indexOf('#') === -1) {
                    this.enteringAnimation = true;
                }

                setTimeout(() => this.enteringAnimation = false, 750);

                ga('set', 'page', val.urlAfterRedirects);
                ga('send', 'pageview');
                this.isHome = nav.url === '/home';
                this.isSettings = nav.url === '/settings';
                this.isOrder = nav.url === '/new-order';
                this.isIFrame = nav.url.startsWith('/request-service') || nav.url === '/new-order';
                if (this.isIFrame) {
                    document.body.style.overflowY = 'hidden';
                } else {
                    document.body.style.overflowY = 'unset';
                }
            }
        });
        const favIconUrl = `${ApiService.endPointDotNet}Entity/Company/fav-icon`;
        this.htmlDocument.getElementById('fav-icon-link').setAttribute('href', favIconUrl);
        this.htmlDocument.title = `${UtilitiesService.applicationName} - Real Estate Portal`;
    }

    get menuItems(): ShellMenuItemModel[] {
        if (!this.overrideMenuItems || this.overrideMenuItems.length === 0) {
            return this.defaultMenuItems;
        }

        return this.overrideMenuItems;
    }

    doMenuItem(menuItem: ShellMenuItemModel) {
        this.menuOpen = false;
        if (menuItem.url) {
            this.router.navigateByUrl(menuItem.url);
        } else {
            menuItem.action();
        }
    }

    @HostListener('window:scroll', [])
    onWindowScroll() {
        this.zone.run(() => {
            this.isScrolled = window.scrollY > 0;
            this.scrollPosition = window.scrollY;

        });
    }
}
