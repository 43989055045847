<div id="first-section" class="">
    <div flex id="content-container" *ngIf="policySummary">
        <div>

            <div style="display: flex;flex-direction: column;height: 100%">
                <div style="flex: auto;">
                    <div class="overview-box-1" aflex>
                        <div nogrow>
                            <img [src]="propertyImageUrl">
                        </div>
                        <div>
                            <h1>Subscription # {{policySummary.policyNumber}}</h1>
                            <div>{{policySummary.propertyAddress.address1}}
                                {{policySummary.propertyAddress.address2}}<br>
                                {{policySummary.propertyAddress.city}}, {{policySummary.propertyAddress.state}}
                                {{policySummary.propertyAddress.postalCode}}</div>
                        </div>
                    </div>

                </div>
                <div style="flex: none" id="main-content">


                    <div class="center">
                        <button mat-button mat-raised-button routerLink="/request-service/{{policySummary.id}}" style="color: #fff;"
                            color="primary">New Service
                            Request</button>
                    </div>

                </div>
                <div style="flex: auto;padding-top: 40px">
                    <div class="container">
                        <div flex>
                            <div class="fuzzy-container fuzzy">
                                <div style="display: flex;flex-direction: column;height: 100%;">
                                    <div style="flex: 1" class="center" topmargin40>
                                        <h1 class="big-data" topmargin40>{{workOrderCount}}</h1>
                                        <div class="data-label">Service Requests</div>
                                    </div>
                                    <div style="flex:none">
                                        <div aflex>
                                            <div></div>
                                            <div nogrow>
                                                <a mat-button (click)="detailVisible = !detailVisible">
                                                    See More <mat-icon>chevron_right</mat-icon>
                                                </a>
                                            </div>
                                        </div>
                                    </div>
                                </div>

                            </div>
                            <div class="fuzzy-container fuzzy">
                                <div style="display: flex;flex-direction: column;height: 100%">
                                    <div style="flex: 1" class="center">
                                        <h1>Coverage</h1>


                                        <h2 topmargin40>{{policySummary.planName}}</h2>
                                        <p>
                                            <span class="data-label">Effective: </span> {{policySummary.effectiveDate |
                                            date: 'shortDate'}} - {{policySummary.expirationDate | date: 'shortDate'}}
                                        </p>
                                        <a mat-button mat-raised-button  class="fuzzy-button" *ngIf="policySummary.status === 'Pending'" routerLink="/change-coverage/{{policySummary.id}}">Change Coverage</a>
                                    </div>
                                    <div style="flex:none" class="center">
                                        <a [href]="contractUrl" mat-button target="_blank">
                                            <mat-icon>description</mat-icon> View Contract
                                        </a>
                                        <a mat-button [href]="invoiceUrl" target="_blank">
                                            <mat-icon>receipt</mat-icon> Invoice
                                        </a>
                                        <a mat-button [href]="certificateUrl" target="_blank">
                                            <mat-icon>verified</mat-icon> Certificate
                                        </a>
                                    </div>
                                </div>
                            </div>

                            <div  class="fuzzy-container fuzzy">
                                <div style="display: flex;flex-direction: column;height: 100%;">
                                    <div style="flex: 1" class="center" topmargin40>
                                        <h1 class="big-data" topmargin40>{{amountDue | currency}}</h1>
                                        <div class="data-label">Amount Due</div>
                                    </div>
                                    <div style="flex:none">
                                        <div aflex>
                                            <div></div>
                                            <div nogrow>
                                                <a mat-button (click)="makePayment()">
                                                    Make Payment <mat-icon>chevron_right</mat-icon>
                                                </a>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>


                    </div>

                </div>

            </div>


        </div>
    </div>
</div>

<div id="side-bar-detail" class="fuzzy" *ngIf="detailVisible">
    <div aflex>
        <div nogrow>
            <button mat-button (click)="detailVisible = false;" class="close-detail-button fuzzy">
                <mat-icon>close</mat-icon>
            </button>
        </div>
        <div style="overflow-x: hidden; overflow-y: auto; height: calc(100vh - 79px);">


            <h1 style="margin-bottom: 40px;">Service Request(s)</h1>

            <div>
                <div class="request-container" *ngFor="let workOrder of workOrders">
                    <div>
                        <div style="position: relative;height: 60px;width: calc(100% - 100px); margin-bottom: 10px;"
                            class="center">

                            <img *ngFor="let line of workOrder.lines" [src]="getItemUrl(line)">
                        </div>
                    </div>
                    <div>
                        <div aflex>
                            <div nogrow>
                                <img *ngIf="!isComplete(workOrder)" src="../../../assets/images/in-progress-icon.svg"
                                    class="progress-icon">
                                <img *ngIf="isComplete(workOrder)" src="../../../assets/images/complete-icon.svg"
                                    class="progress-icon">
                            </div>
                            <div>
                                Service Request # {{workOrder.number}}<br>
                                {{workOrder.itemName}}<br>
                                Opened: {{workOrder.createdDate | date: 'shortDate'}}
                            </div>
                        </div>
                    </div>
                </div>

            </div>
        </div>
    </div>
</div>