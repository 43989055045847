import { DatePipe } from '@angular/common';

export class SalesReport {
    id: string;
    salesRegionName: string;
    accountExecutiveName: string;
    salesAreaName: string;
    salesTerritoryName: string;
    salesTerritoryId: string;
    salesAreaId: string;
    accountExecutiveId: string;
    salesRegionId: string;
    policyCount: number;
    totalPolicies: number;
    basePrice: number;
    coverageType: string;
    month: Date;
    salesTarget: number;
    salesClosingTarget: number;
    totalPrice: number;

    // Client-side only
     priorYearCount: number;
     priorYearTotalPremium: number;


    private displayMonthPrivate: string;
    get displayMonth() {
        if(!this.displayMonthPrivate) {
            const datePipe = new DatePipe('en-US');
            this.displayMonthPrivate = datePipe.transform(this.month, 'MMMM yyyy');
        }

        return this.displayMonthPrivate;
    }

    
    get growthCountPct() {
    
        if(this.priorYearCount === 0) {
            return 0;
        }

        return (this.policyCount / this.priorYearCount) - 1;
    }

    get growthPremiumPct() {
        if(this.priorYearTotalPremium === 0) {
            return 0;
        }
        
        return (this.totalPrice / this.priorYearTotalPremium) - 1;
    }

    private _countLeft;
    get countLeft() {
        if (!this._countLeft) {
            setTimeout(() => {
                let pct = this.growthCountPct;
                if (pct < -.5) {
                    pct = -.5;
                }
                if (pct > .5) {
                    pct = .5;
                }

                if (pct < 0) {

                    this._countLeft = ((.5 + pct) * 100) + '%';
                } else {
                    this._countLeft = '50%';
                }
            }, 100);
            return '50%';
        }

        return this._countLeft;
    }

    private _countWidth;
    get countWidth() {
        if (!this._countWidth) {
            setTimeout(() => {
                let pct = this.growthCountPct;
                if (pct < -.5) {
                    pct = -.5;
                }
                if (pct > .5) {
                    pct = .5;
                }
                this._countWidth = Math.abs((pct * 100)) + '%';
            }, 100);
            return '0%';
        }
        return this._countWidth;
    }

    private _premiumLeft;
    get premiumLeft() {
        if (!this._premiumLeft) {
            setTimeout(() => {
                let pct = this.growthPremiumPct;
                if (pct < -.5) {
                    pct = -.5;
                }
                if (pct > .5) {
                    pct = .5;
                }

                if (pct < 0) {

                    this._premiumLeft = ((.5 + pct) * 100) + '%';
                } else {
                    this._premiumLeft = '50%';
                }
            }, 100);
            return '50%';
        }

        return this._premiumLeft;
    }

    private _premiumWidth;

    get premiumWidth() {
        if (!this._premiumWidth) {
            setTimeout(() => {
                let pct = this.growthPremiumPct;
                if (pct < -.5) {
                    pct = -.5;
                }
                if (pct > .5) {
                    pct = .5;
                }
                this._premiumWidth = Math.abs((pct * 100)) + '%';
            }, 100);
            return '0%';
        }

        return this._premiumWidth;
    }
}