import { Component, OnInit, ViewChild } from '@angular/core';
import { EntityApiService } from "@cogent/client/shared/services/api/entity-api.service";
import { MatDialog } from '@angular/material/dialog';
import { ChangePasswordComponent } from '../change-password/change-password.component';
import { EntityFindModalComponent } from '../entity-find/entity-find-modal.component';
import { ActivatedRoute } from '@angular/router';
import { SwapableImageComponent } from '@cogent/client/shared/components/misc/swapable-image/swapable-image.component';
import { MissionService } from '@cogent/client/shared/services/mission-service';
import { Entity, Tag } from '@upkeeplabs/models/cogent';

@Component({
    selector: 'app-settings',
    templateUrl: './settings.component.html',
    styleUrls: ['./settings.component.css']
})
export class SettingsComponent implements OnInit {


    currentEntity: Entity;
    saving = false;
    parent: Entity;
    imageUrl: string;
    parentImageUrl: string;
    flipped = false;
    customerId: string;
    anniversary = false;
    sendPostcards = false;
    redboxTag: Tag;

    @ViewChild('swapableImage') swapableImage: SwapableImageComponent;

    constructor(private entityApi: EntityApiService,
        private missionService: MissionService,
        private dialog: MatDialog,
        private activatedRoute: ActivatedRoute) { }

    ngOnInit() {
        this.entityApi.getLoggedInUser(true).then(user => {
            this.currentEntity = user;
            this.customerId = user.id;
            this.activatedRoute.queryParams.subscribe(params => {
                if (params.changeCompany) {
                    this.changeParent();
                }
            });
            if (user && user.parentId) {
                this.entityApi.getFullEntity(user.parentId).then(parent => {
                    this.parent = parent;
                });
            }
            this.imageUrl = `${this.entityApi.getImageUrl(this.currentEntity.id)}?v=${new Date().getTime()}`;
            this.parentImageUrl = `${this.entityApi.getImageUrl(this.currentEntity.parentId)}?v=${new Date().getTime()}`;
            this.entityApi.getTags(this.currentEntity.id).then(tags => {
                this.redboxTag = tags.find(i => i.id.toUpperCase() === '1990A535-C2FD-4847-B4A2-DB9858D3FDD9');
                if (this.redboxTag) {
                    this.sendPostcards = true;
                } else {
                    this.sendPostcards = false;
                }
            });
        });
    }

    showFileUpload(id) {
        document.getElementById(id).click();
    }

    handleFiles(files, target) {
        if (files.srcElement) {
            files = files.srcElement.files;
        }
        for (let i = 0; i < files.length; i++) {
            const file = files[i];
            //this.imageType = file.type;
            const imageType = /^image\//;

            if (!imageType.test(file.type)) {
                continue;
            }
            const reader = new FileReader();

            const setUrl = (url) => {

                if (target === 'company-logo') {
                    this.parentImageUrl = url;
                } else {
                    this.imageUrl = url;
                }

            };
            reader.onload = (function () {
                return function (e) {

                    (this as any).dragTargetActive = false;
                    setUrl(e.target.result);

                };
            })();

            reader.readAsDataURL(file);
        }
    }

    async save() {
        this.saving = true;
        const promises = [];
        promises.push(this.entityApi.updateEntity(this.currentEntity, false));
        if (this.imageUrl != null && this.imageUrl.startsWith('data:image')) {
            const index = this.imageUrl.indexOf(',');
            const justBase64 =
                this.imageUrl.substring(index + 1, this.imageUrl.length);
            promises.push(
                this.entityApi.updateEntityPhoto(
                    this.currentEntity.id, justBase64,
                    this.swapableImage.imageType));
        }

        if (this.parentImageUrl != null && this.parentImageUrl.startsWith('data:image')) {
            const index = this.parentImageUrl.indexOf(',');
            const justBase64 =
                this.parentImageUrl.substring(index + 1, this.parentImageUrl.length);
            promises.push(
                this.entityApi.updateEntityPhoto(
                    this.currentEntity.parentId, justBase64,
                    this.swapableImage.imageType));
        }

        await Promise.all(promises);
        if (this.sendPostcards && !this.redboxTag) {
            await this.entityApi.addTags(this.currentEntity.id, [{ id: '1990A535-C2FD-4847-B4A2-DB9858D3FDD9' } as any]);
        } else if (!this.sendPostcards && this.redboxTag) {
            await this.entityApi.removeTags(this.currentEntity.id, [{ id: '1990A535-C2FD-4847-B4A2-DB9858D3FDD9' } as any]);
        }
        this.saving = false;
        this.missionService.showSuccessToast('Your savings have been saved');
    }

    showChangePassword() {
        const ref = this.dialog.open(ChangePasswordComponent);
        ref.afterClosed().subscribe(results => {
            if (results) {
                this.missionService.showSuccessToast('Your password has been changed');
            }
        });
    }

    changeParent() {
        let parentType = '';
        if (this.currentEntity.type === 'Agent' || this.currentEntity.type === 'Customer') {
            parentType = 'Branch';
        } else if (this.currentEntity.type === 'Branch') {
            parentType = 'Real_Estate_Company';
        } else if (this.currentEntity.type === 'ClosingOfficer') {
            parentType = 'Title_Company';
        } else if (this.currentEntity.type === 'TransactionCoordinator') {
            parentType = 'Branch';
        } else if (this.currentEntity.type === 'Loan_Officer') {
            parentType = 'Mortgage_Company';
        } else if (this.currentEntity.type === 'Insurance_Agent') {
            parentType = 'Insurance_Branch';
        }

        EntityFindModalComponent.FindEntity(this.dialog, parentType, async entity => {
            if (entity) {
                this.parent = entity;
                this.currentEntity.parentId = entity.id;
                await this.entityApi.updateParent(this.currentEntity.id, entity.id);
            }
        });
    }
}
