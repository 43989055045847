import { Component, OnInit } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { ActivatedRoute } from '@angular/router';
import { SettingsApiService } from '@cogent/client/shared/services/api/settings-api.service';
import { PolicyApiService } from '@cogent/client/shared/services/api/policy-api.service';
import { AuthService } from '@cogent/client/auth';
import { DialogsService } from '@cogent/client/shared/services/dialog-service/dialog.service';
import { PolicySummary } from '@cogent/client/shared/models/policies/policy-summary.model';
import { WorkOrderLineSummaryClient } from '@cogent/shared/models/service/work-order-line-summary.model';
import { WorkOrderSummaryClient } from '@cogent/client/shared/models/service/work-order-summary-client.model';
import { ApiService } from '@cogent/client/api';
import { TakePaymentComponent } from '../take-payment/take-payment.component';
import { InvoiceSummary } from '@upkeeplabs/models/cogent';
import { AccountingApiService } from '@cogent/client/shared/services/api/accounting-api.service';


@Component({
    selector: 'app-view-policy',
    templateUrl: './view-policy.component.html',
    styleUrls: ['./view-policy.component.css']
})
export class ViewPolicyComponent implements OnInit {

    id: string;
    policySummary: PolicySummary;
    detailVisible = false;
    baseUrl: string;
    baseUrl2: string;
    workOrders: WorkOrderSummaryClient[];
    invoices: InvoiceSummary[];


    constructor(private policyApi: PolicyApiService,
        private api: ApiService,
        private dialogs: DialogsService,
        private settingsApi: SettingsApiService,
        private accountingApi: AccountingApiService,
        private dialog: MatDialog,
        private authService: AuthService,
        private activatedRoute: ActivatedRoute) { }

    ngOnInit(): void {
        this.api.getApiEndpointDotNet().then(url => this.baseUrl = url);
        this.api.getApiEndpointNode().then(url2 => this.baseUrl2 = url2);
        this.activatedRoute.params.subscribe(params => {
            this.id = params.id;

            this.refresh();


        });
    }

    private refresh() {
        this.policyApi.getPolicySummary(this.id).then(ps => {
            this.policySummary = ps;
        });
        this.policyApi.getPolicyClaims(this.id).then(cs => {
            this.workOrders = cs as any;
        });

        this.accountingApi.getPolicyInvoices(this.id).then(invoices => {
            this.invoices = invoices as any;
        });
    }

    get amountDue() {
        if (!this.invoices || this.invoices.filter(i => i.invoiceType === 'Premium' && i.amountDue > 0).length === 0) {
            return 0;
        }

        return this.invoices.filter(i => i.invoiceType === 'Premium' && i.amountDue > 0).map(i => i.amountDue).reduce((a, b) => a + b);
    }

    makePayment() {
        const ref = this.dialog.open(TakePaymentComponent, { data: { invoices: this.invoices, policySummary: this.policySummary } });
        ref.afterClosed().subscribe(results => {
            this.refresh();
        });
    }

    get contractUrl() {
        if (!this.policySummary || !this.baseUrl) {
            return null;
        }

        return `${this.baseUrl2}policy/contract/pdf/${this.id}`;
    }

    getItemUrl(line: WorkOrderLineSummaryClient) {
        return `${this.baseUrl}WorkOrderItem/${line.itemId}/photo`;
    }

    isComplete(workOrderSummary: WorkOrderSummaryClient) {
        return workOrderSummary.status === 'Invoiced' || workOrderSummary.status === 'Paid'
            || workOrderSummary.status === 'Invoice Accepted' || workOrderSummary.status === 'Complete';
    }

    login() {
        // if (this.policySummary.status !== 'Active' && this.policySummary.status !== 'Pending') {
        //     this.dialogs.alert('Not Active', 'Sorry, your subscription is not currently active.<br>Please call 801-804-5132 for further assistance.');
        //     return;
        // }
        // window.localStorage.setItem("redirectTo", "/request-service/" + this.policySummary.id);
        // this.authService.logIn(this.policySummary.holderId);
        //this.impersonate();
    }



    get invoiceUrl() {
        if (!this.policySummary || !this.baseUrl) {
            return null;
        }

        return `${this.baseUrl}policy/${this.id}/title-invoice/pdf`;

    }

    get certificateUrl() {
        if (!this.policySummary || !this.baseUrl) {
            return null;
        }

        return `${this.baseUrl}policy/${this.id}/certificate/pdf?showContent=true`;

    }

    get propertyImageUrl() {
        if (!this.id) {
            return null;
        }

        return `${this.baseUrl}policy/${this.id}/StreetView`
    }

    get workOrderCount() {
        if (!this.workOrders) {
            return 0;
        }

        return this.workOrders.length;
    }

}
