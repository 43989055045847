import { Component, OnInit, HostListener } from '@angular/core';
import { CustomerRepositoryService } from '@cogent/client/shared/services/api/customer.service';
import { DialogsService } from '@cogent/client/shared/services/dialog-service/dialog.service';
import { UtilitiesService } from '@cogent/client/shared/logic/utilities';
import { MissionService } from '@cogent/client/shared/services/mission-service';
import { Login } from '@cogent/shared/models/user/login.model';
import { Address, Entity } from '@upkeeplabs/models/cogent';


@Component({
    selector: 'app-register',
    templateUrl: './register.component.html',
    styleUrls: ['./register.component.css']
})
export class RegisterComponent implements OnInit {

    entity: Entity;
    autocomplete: any;
    confirmPassword: string;
    saving = false;
    loginExists = false;
    selectedIndex = 0;
    existingEntities: any[];
    registrationCode: number;
    sendingRegistrationCode = false;
    canSaveEntity: boolean;

    login: Login;


    constructor(
        private customerRepository: CustomerRepositoryService,
        private dialogService: DialogsService,
        private missionService: MissionService,
    ) {
        this.entity = new Entity();
        this.entity.id = UtilitiesService.newid();
        this.login = new Login();
        this.entity.address = new Address();
        this.entity.phoneNumbers = [];
        this.login.id = UtilitiesService.newid();
    }

    ngOnInit() {
        this.selectedIndexChange(0);
    }

    get passwordsDoNotMatch() {
        return this.login.passwordHash && this.confirmPassword
            && this.login.passwordHash !== this.confirmPassword;
    }

    get canSave() {
        return this.login.passwordHash
            && !this.passwordsDoNotMatch
            && this.canSaveEntity
            && !this.loginExists
            && UtilitiesService.validateEmail(this.entity.email);
    }

    selectedIndexChange(index) {
        document.location.hash = index;
    }
    
    @HostListener('window:hashchange', ['$event'])
    watchUrlHash() {
        let hash = window.location.hash;
        if (hash) {
            hash = hash.replace('#', '');
        }
        let hashNumber = parseInt(hash, 10);

        if (isNaN(hashNumber)) {
            hashNumber = 0;
        }

        if (!isNaN(hashNumber)) {
            this.selectedIndex = hashNumber;
        }
    }

    get step1CanGoNext() {
        return this.login.passwordHash
            && !this.passwordsDoNotMatch
            && this.confirmPassword
            && this.entity.email
            && this.login.entityType
            && UtilitiesService.validateEmail(this.entity.email);
    }

    async step1GoNext() {
        this.saving = true;
        const userExists = await this.customerRepository.loginExists(this.entity.email, this.login.entityType);

        if (userExists) {

            this.dialogService.alert('User Name Taken', 'Sorry, it looks like that user name is already in use.');
            this.saving = false;
            return;
        }
        this.existingEntities = await this.customerRepository.findExistingEntitiesByEmail(this.entity.email, this.login.entityType);

        this.saving = false;
        this.entity.type = this.login.entityType;
        if (this.existingEntities && this.existingEntities.length > 0) {
            this.selectedIndex = 1;

        } else {
            this.selectedIndex = 2;
        }
    }

    showVerifyDialog(entity) {
        entity.showCodeEntry = false;
        this.sendingRegistrationCode = true;
        this.customerRepository.sendVerificationCode({
            phoneNumberId: entity.sendOption === 'Home' ? entity.homeNumberId : (entity.sendOption === 'Mobile' ? entity.mobileNumberId : null),
            email: entity.sendOption === 'Email' ? entity.email : null,
            entityId: entity.id,
        }).then(() => {
            this.missionService.showSuccessToast('The code has been sent');
            entity.showCodeEntry = true;
            this.sendingRegistrationCode = false;
        });
    }

    async registerWithCode(entity) {
        this.saving = true;
        this.login.userName = this.entity.email;
        this.login.entityId = entity.id;
        const args = {
            entityId: entity.id,
            code: this.registrationCode,
            login: this.login,
        };

        const result = await this.customerRepository.completeRegistrationWithCode(args);
        this.saving = false;
        if (result) {
            this.selectedIndex = 3;
        } else {
            this.dialogService.alert('Could Not Register', 'Sorry, we could not register you with the code provided.');
        }
    }

    async save() {
        this.saving = true;
        this.login.userName = this.entity.email;
        this.login.entityId = this.entity.id;
        if (this.entity.phoneNumbers) {
            for (const phoneNumber of this.entity.phoneNumbers) {
                phoneNumber.entityId = this.entity.id;
                if (!phoneNumber.id) {
                    phoneNumber.id = UtilitiesService.newid();
                }
            }

            this.entity.phoneNumbers = this.entity.phoneNumbers.filter(i => UtilitiesService.validatePhoneNumber(i.number));
        }
        if (this.entity.address && !this.entity.address.id) {
            this.entity.address.id = UtilitiesService.newid();
        }

        if (this.entity.address) {
            this.entity.addressId = this.entity.address.id;
        }
        this.entity.type = this.login.entityType;
        this.entity.loginId = this.login.id;
        await this.customerRepository.completeNewRegistration({
            login: this.login,
            entity: this.entity,
            entityAddress: this.entity.address,
            entityPhoneNumbers: this.entity.phoneNumbers,
        });

        this.saving = false;
        this.selectedIndex = 3;
    }
}
