<!-- <div aflex>
    <div>

    </div>
    <div nogrow>
        <button mat-button [mat-dialog-close]>
            <mat-icon>close</mat-icon>
        </button>
    </div>
</div> -->
<div id="header-bg"></div>


<div aflex class="wizard-header" *ngIf="selectedIndex < 3">
    <div nogrow>
        <div id="header-progress">
            <app-circle-widget-small [height]="40" [percent]="pct"></app-circle-widget-small>
        </div>
        <div id="steps-label">
            {{selectedIndex + 1}} of {{totalSteps}}
        </div>
    </div>
    <div nogrow>

        <div *ngIf="currentStepDesc" class="slide-in-up">
            <h2>{{currentStepDesc}}</h2>
            <div class="next" *ngIf="(selectedIndex + 1) < totalSteps">Next: {{nextStep}}</div>
        </div>
    </div>
    <div style="margin-left: 0;">

    </div>
    <div nogrow style="margin-left: 0;">
        <button mat-button *ngIf="selectedIndex > 0" (click)="selectedIndex = selectedIndex - 1">
            <mat-icon>chevron_left</mat-icon>
        </button>
        <button mat-button mat-raised-button *ngIf="!lastStep" color="primary" class="small-primary"
            (click)="selectedIndex = selectedIndex + 1">
            Next <mat-icon>chevron_right</mat-icon>
        </button>

    </div>
</div>

<mat-tab-group [(selectedIndex)]="selectedIndex" (selectedIndexChange)="selectedIndexChange($event)" class="hide-tabs">
    <mat-tab label="Plan">

        <div *ngIf="loading" id="loading">
            <mat-spinner></mat-spinner><br>
            Loading Plans...
        </div>
        <app-plan-offerings *ngIf="plans" [plans]="plans" [hideMonthlySelection]="true" [hideTitle]="true"
            (selectedPlanChange)="selectedPlanChange($event)" [(selectedPlan)]="selectedPlan"></app-plan-offerings>

    </mat-tab>
    <mat-tab label="Options">

        <div class="container" *ngIf="selectedPlan">
            <div id="mobile-options">
                <div class="mobile-option" aflex *ngFor="let option of selectedPlan.optionalItems">

                    <div nogrow>
                        <mat-checkbox [(ngModel)]="option.selected" (change)="optionSelected(option)">
                        </mat-checkbox>
                    </div>
                    <div>
                        <h2 (click)="option.selected = !option.selected; optionSelected(option);">{{option.name}}
                        </h2>
                        <div>

                        </div>
                        <div>
                            <div topmargin10 *ngIf="option.selected && !option.allowQuantity"><span
                                    class="data-label">Quantity:
                                </span> 1</div>
                            <div aflex *ngIf="option.selected && option.allowQuantity" topmargin10>
                                <div nogrow class="data-label">Quantity: </div>
                                <div>

                                    <mat-form-field appearance="outline"
                                        style="margin-top: -20px; width: 100px !important">
                                        <mat-select [(ngModel)]="option.quantity">
                                            <mat-option *ngFor="let number of numbers" [value]="number">
                                                {{ number }}
                                            </mat-option>
                                        </mat-select>
                                    </mat-form-field>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div nogrow>
                        <span *ngIf="!useMonthly">{{option.price| currency:'USD':'symbol':'1.2-2'}}</span>
                        <span *ngIf="useMonthly">{{option.priceRecurring| currency:'USD':'symbol':'1.2-2'}}</span>
                    </div>
                    <div nogrow>
                        <button mat-button (click)="showContractLanguage(option)">
                            <mat-icon>search</mat-icon>
                        </button>
                    </div>
                </div>
            </div>


            <div class="table soft-light" id="desktop-options">
                <div class="table-header">
                    <div flex3>Item</div>
                    <div flex1>Price</div>
                    <div flex1>Selected</div>
                    <div flex1>Qty</div>
                    <div nogrow style="width: 60px"></div>
                </div>
                <div class="table-row" *ngFor="let option of selectedPlan.optionalItems">
                    <div flex3>{{option.name}}</div>
                    <div flex1>
                        <span *ngIf="!useMonthly">{{option.price| currency:'USD':'symbol':'1.2-2'}}</span>
                        <span *ngIf="useMonthly">{{option.priceRecurring| currency:'USD':'symbol':'1.2-2'}}</span>
                    </div>
                    <div flex1>
                        <mat-checkbox [(ngModel)]="option.selected" (change)="optionSelected(option)">
                        </mat-checkbox>

                    </div>
                    <div flex1>
                        <span *ngIf="option.selected && !option.allowQuantity">
                            1</span>
                        <div style="margin-top: -5px" *ngIf="option.selected && option.allowQuantity">
                            <mat-form-field appearance="outline">
                                <mat-select [(ngModel)]="option.quantity">
                                    <mat-option *ngFor="let number of numbers" [value]="number">
                                        {{ number }}
                                    </mat-option>
                                </mat-select>
                            </mat-form-field>
                        </div>
                    </div>
                    <div nogrow style="width: 60px">
                        <button mat-button (click)="showContractLanguage(option)">
                            <mat-icon>search</mat-icon>
                        </button>
                    </div>
                </div>
            </div>
        </div>
    </mat-tab>
    <mat-tab label="Review">
        <div class="small-container">
            <ng-container *ngIf="selectedPlan">
                <div aflex>
                    <div nogrow>
                        <h2>{{selectedPlan.name}}</h2>
                    </div>
                    <div class="dotted"></div>
                    <div nogrow>{{planBasePrice | currency}}</div>
                </div>

                <div aflex *ngFor="let option of selectedOptions" class="options-line">

                    <div nogrow class="indent plan-item-field">
                        {{option.name}}

                        <span *ngIf="option.quantity > 1"> (x{{option.quantity}})</span>
                    </div>
                    <div class="dotted"></div>
                    <div nogrow>
                        {{option.quantity * option.price | currency}}
                    </div>
                </div>
                <!-- <div *ngIf="priceDifferential" aflex>
                    <div nogrow class="indent plan-item-field">Additional Options</div>
                    <div class="dotted"></div>
                    <div nogrow>{{priceDifferential | currency}}</div>
                </div> -->
            </ng-container>
            <div topmargin30 aflex>
                <div></div>
                <div nogrow>
                    <!-- <div class="data-label right">Total Price</div>
                    <h1 class="big-data">{{totalPriceDisplay | currency}}</h1> -->
                    <app-display-total [total]="totalPriceDisplay" label="Total Price"></app-display-total>
                </div>
            </div>

            <div flex topmargin40>
                <div></div>
                <div nogrow>
                    <button mat-button mat-raised-button [disabled]="!canSave" large mobile-block color="primary"
                        (click)="save()">
                        <mat-spinner *ngIf="saving" class="thirty"></mat-spinner>
                        Save
                    </button>
                </div>
            </div>

        </div>
    </mat-tab>

    <mat-tab>
        <div class="center">
            <div topmargin40></div>
            <app-check-circle-animated height="150px"></app-check-circle-animated>

            <h1 topmargin40>Coverage Changed</h1>

            <div topmargin40></div>
            <a *ngIf="policySummary" mat-button mat-raised-button color="primary"
                routerLink="/view-subscription/{{policySummary.id}}">
                Open Subscription
            </a>
            <div topmargin40 class="dashed-left-and-right">
                Other Options
            </div>
            <div>
                <a class="document-link" [href]="contractUrl" mobile-block mat-button target="_blank">
                    <mat-icon>description</mat-icon> View Contract
                </a>
                <a class="document-link" mat-button [href]="invoiceUrl" mobile-block target="_blank">
                    <mat-icon>receipt</mat-icon> View Invoice
                </a>
                <a class="document-link" mat-button [href]="certificateUrl" mobile-block target="_blank">
                    <mat-icon>verified</mat-icon> View Certificate
                </a>

            </div>
        </div>
    </mat-tab>

</mat-tab-group>