import { Component, Inject } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialog, MatDialogRef } from '@angular/material/dialog';
import { EntityApiService } from "@cogent/client/shared/services/api/entity-api.service";
import { Entity } from '@upkeeplabs/models/cogent';

@Component({
    templateUrl: './entity-edit-modal.component.html',
    styleUrls: ['./entity-edit-modal.component.css'],
})
export class EntityEditModalComponent {

    entity: Entity;
    canSave = false;
    saving: boolean;

    public static EditEntity(dialog: MatDialog, entity: Entity, callback: (entity: Entity) => any) {
        dialog.open(EntityEditModalComponent, { data: { entity: entity, callback: callback } });
    }

    constructor(
        @Inject(MAT_DIALOG_DATA) public data: any,
        private entityApi: EntityApiService,
        private dialogRef: MatDialogRef<EntityEditModalComponent>,
        private dialog: MatDialog
    ) {
        entityApi.getFullEntity(data.entity.id).then(dbEntity => {
            this.entity = dbEntity;
            this.canSave = true;
        });
    }

    saveEntity() {
        this.saving = true;
        this.entityApi.updateEntity(this.entity, false).then(entity => {
            this.data.callback(this.entity);
            this.dialog.closeAll();
            this.saving = false;
        });
    }

    closeDialog() {
        this.dialogRef.close();
    }
}
