import { AdditionalEmail, Address, Entity, PhoneNumber, Policy, PolicyAdjustment, StripeCard } from '@upkeeplabs/models/cogent';
import { SalesItemModel } from '@cogent/shared/models/plans/sales-item.model';



export class NewPolicy {
    policy: Policy = new Policy();
    holder: Entity = new Entity();
    coHolder: Entity = new Entity();
    property: Address = new Address();
    holderAddress: Address = new Address();
    holderPhoneNumbers: PhoneNumber[] = [];
    options: SalesItemModel[];
    adjustments: PolicyAdjustment[];
    selectedCard: StripeCard;
    coverageType: string;
    promotionCode: string;
    billTo: string;
    additionalEmailAddresses: AdditionalEmail[];

    constructor() {
        this.holder.type = 'Customer';
        this.coHolder.type = 'Customer';
        // this.policy.commissionable = true;
    }
}
